import { useEffect } from "react";
import { useEnv } from "../contexts";
import logo from "../icons/gdfproject.png";
import { useTranslation } from 'react-i18next';

// Landing Auth component - Login Dialog
const AboutUs = () => {
  const { setEnvState } = useEnv(); // Get Environment context Setter

  // Notifiy the App of this location and Header Nav requirements
  useEffect(() => {
      // console.log('we are on rreports menu');
      setEnvState(state => ({...state, whereAbout: t('navigation_element.nav_item_title_about_us'), backPath: "", homePath: "/fe"}));
      return () => {
          setEnvState(state => ({...state, whereAbout: "", backPath: "", homePath: ""}));
      }
     // eslint-disable-next-line
   }, []);

  // --------------------- start language matter --------------------------
  // for language
  const { t } = useTranslation();
  // --------------------- end language matter --------------------------

  return (
      <div className="flex min-h-full items-center mt-14 md:mt-4 justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md space-y-8">
            {/* Header */}
            <div>
                {/* logo */}
                <div className="flex justify-center">
                <img
                    src={logo}
                    className="w-[80px] h-auto"
                    alt="GDF 512x512 sized logo"
                />
                </div>
            </div>
            {/* Start Content */}
            <div className="text-gray-600/90 text-lg col-span-2 flex flex-col justify-center items-center px-6 bg-gray-50">
                <span>{t('hamburger_messages.about_us_mission')}</span>
                <span>{t('hamburger_messages.about_us_background')}</span>
                <span>{t('hamburger_messages.about_us_vision')}</span>
            </div>
            {/* End Content */}
            <div className="flex flex-col justify-center items-center mt-14 md:mt-8 text-sm text-gray-400/90 font-semibold border-t-[3px] border-gray-200 pt-3">
                    <span>{t('home_page.courtesy')}</span>
                    <span>{t('home_page.web')}</span>
                    <span>{t('home_page.email')}</span>
                    <span>{t('home_page.copyright')}</span>
            </div>
        </div>
      </div>

  );
  
};


export {AboutUs};
