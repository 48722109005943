import { httpClient } from "./httpClient";

// Create a resource
export const createResource = async (createURL, payload, t) => {

  try {
    const resp = await httpClient.post(createURL, payload);
    return resp.data;
  } catch (error) {

    // : comment out this
    // console.log("createResource POST: ", error);
    // console.log("We are ready to set createResource");
    // console.log('error message: ' + error.message);
    // console.log('error status: ', error.response.status);
    // console.log('error status: ', error.response?.statusText);
    // console.log('error error: ', error.response?.data);

    // : log out user if 401
    if (error.response.status === 401) {handleLogout();}

    return {
      success: false,
      status: error.response?.status ? error.response?.status : 101,
      message: error.response?.statusText
        ? error.response?.statusText
        : t("error_message.unable_to_reach_gdf"), 
      errors: error.response?.data?.errors,
    };
  }
};

// Guest create farm resource request
export const guestCreateResource = async (createURL, payload, t) => {
  try {
    // Request for Laravel CSRF cookie
    await httpClient.get(process.env.REACT_APP_CSRF_URL);

    const resp = await httpClient.post(createURL, payload);
    return resp.data;
  } catch (error) {

    // : log out user if 401
    if (error.response.status === 401) {handleLogout();}
    
    return {
      success: false,
      status: error.response?.status ? error.response?.status : 101,
      message: error.response?.statusText
        ? error.response?.statusText
        : t("error_message.unable_to_reach_gdf"),
      errors: error.response?.data?.errors,
    };
  }
};

// Get a resource / Get all resouces if payload is null
export const getResource = async (getURL, payload = null, t) => {
  try {
    // console.log("Calling Api..", Math.ceil(Math.random() * 10));

    const resp =
      payload === null
        ? await httpClient.get(getURL)
        : await httpClient.get(getURL + "/" + payload.resourceId);

    return resp.data;
  } catch (error) {

    // : log out user if 401
    if (error.response.status === 401) {handleLogout();}

    return {
      success: false,
      status: error.response?.status ? error.response?.status : 101,
      message: error.response?.statusText
        ? error.response?.statusText
        : t("error_message.unable_to_reach_gdf"),
      errors: error.response?.data?.errors,
    };
  }
};

// Update a resource
export const updateResource = async (updateUrl, payload, t) => {
  // console.log('update resource is called:');
  try {
    const resp = await httpClient.patch(updateUrl, payload);
    return resp.data;
  } catch (error) {

    // : log out user if 401
    if (error.response.status === 401) {handleLogout();}

    return {
      success: false,
      status: error.response?.status ? error.response?.status : 101,
      message: error.response?.statusText
        ? error.response?.statusText
        : t("error_message.unable_to_reach_gdf"),
      errors: error.response?.data?.errors,
    };
  }
};

// Delete a resource
export const deleteResource = async (deleteUrl, payload, t) => {
  try {
    // console.log("Calling Api..", Math.ceil(Math.random()));

    const resp = await httpClient.delete(deleteUrl);
    return resp.data;
  } catch (error) {

    // : log out user if 401
    if (error.response.status === 401) {handleLogout();}

    return {
      success: false,
      status: error.response?.status ? error.response?.status : 101,
      message: error.response?.statusText
        ? error.response?.statusText
        : t("error_message.unable_to_reach_gdf"),
      errors: error.response?.data?.errors,
    };
  }
};

export const sendMail = async (
  createURL,
  emailToAddress,
  emailFromAddress,
  emailSubject,
  emailContent,
  t
) => {
  try {
    const resp = await httpClient.post(
      createURL,
      emailToAddress,
      emailFromAddress,
      emailSubject,
      emailContent
    );
    return resp.data;
  } catch (error) {

    // : comment out this
    // console.log("sendMail POST: ", error);
    // console.log("We are ready to set sendMail");
    // console.log('error message: ' + error.message);
    // console.log('error status: ', error.response.status);

    //  log out user if 401
    if (error.response.status === 401){
      // console.log("// Call the logout function from sendMail");
      // await logout();
    }

    return {
      success: false,
      status: error.response?.status ? error.response?.status : 101,
      message: error.response?.statusText
        ? error.response?.statusText
        : t("error_message.unable_to_reach_gdf"),
      errors: error.response?.data?.errors,
    };
  }
};

// Create a function to handle logging out the user
const handleLogout = () => {
  localStorage.removeItem(process.env.REACT_APP_AUTH_STORAGE_KEY);
    // Force page refresh and redirect to login page
    window.location.href = "/fe";
};

