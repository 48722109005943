function subscribe(eventName, listener) {
    document.addEventListener(eventName, listener);
  }
  
  function unsubscribe(eventName, listener) {
    document.removeEventListener(eventName, listener);
  }
  
  function publish(eventName) {
    const event = new Event(eventName);
    document.dispatchEvent(event);
    //const res = document.dispatchEvent(event);
    //console.log(eventName, "dispatched", "results", res);
  }
  
//   function publishWithData(eventName, data) {
//     const event = new CustomEvent(eventName, { detail: data });
//     document.dispatchEvent(event);
//   }
  
  export const useEvents = () => ({ publish, subscribe, unsubscribe});