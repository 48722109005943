import { useEffect } from "react";
import { useEnv } from "../contexts";
import logo from "../icons/gdfproject.png";
import { useTranslation } from 'react-i18next';

// Landing Auth component - Login Dialog
const TermsAndConditions = () => {
  const { setEnvState } = useEnv(); // Get Environment context Setter

  // Notifiy the App of this location and Header Nav requirements
  useEffect(() => {
      // console.log('we are on rreports menu');
      setEnvState(state => ({...state, whereAbout: t('navigation_element.nav_item_title_terms_and_conditions'), backPath: "", homePath: "/fe"}));
      return () => {
          setEnvState(state => ({...state, whereAbout: "", backPath: "", homePath: ""}));
      }
     // eslint-disable-next-line
   }, []);

  // --------------------- start language matter --------------------------
  // for language
  const { t } = useTranslation();
  // --------------------- end language matter -------------------------- 

  return (
      <div className="flex min-h-full items-center mt-14 md:mt-4 justify-center py-12 px-4 sm:px-6 lg:px-8 z-0">
        <div className="w-full max-w-md space-y-8">
            {/* Header */}
            <div>
                {/* logo */}
                <div className="flex justify-center">
                <img
                    src={logo}
                    className="w-[80px] h-auto"
                    alt="GDF 512x512 sized logo"
                />
                </div>
            </div>
            
            {/* Start Content */}
            <div className="text-gray-600/90 text-lg col-span-2 px-6 bg-gray-50">
            <h2 className="text-xl font-bold mb-2">{t('terms_and_conditions.title')}</h2>

            <ol className="list-decimal pl-6">
                <li>
                <h3 className="font-bold">{t('terms_and_conditions.section_title1')}</h3>
                <p>{t('terms_and_conditions.section_content1')}</p>
                </li>

                <li>
                <h3 className="font-bold">{t('terms_and_conditions.section_title2')}</h3>
                <p>{t('terms_and_conditions.section_content2')}</p>
                </li>

                <li>
                <h3 className="font-bold">{t('terms_and_conditions.section_title3')}</h3>
                <p>{t('terms_and_conditions.section_content3')}</p>
                </li>

                <li>
                <h3 className="font-bold">{t('terms_and_conditions.section_title4')}</h3>
                <p>{t('terms_and_conditions.section_content4')}</p>
                </li>

                <li>
                <h3 className="font-bold">{t('terms_and_conditions.section_title5')}</h3>
                <p>{t('terms_and_conditions.section_content5')}</p>
                </li>

                <li>
                <h3 className="font-bold">{t('terms_and_conditions.section_title6')}</h3>
                <p>{t('terms_and_conditions.section_content6')}</p>
                </li>

                <li>
                <h3 className="font-bold">{t('terms_and_conditions.section_title7')}</h3>
                <p>{t('terms_and_conditions.section_content7')}</p>
                </li>

                <li>
                <h3 className="font-bold">{t('terms_and_conditions.section_title8')}</h3>
                <p>{t('terms_and_conditions.section_content8')}</p>
                </li>
            </ol>
            </div>
            {/* End Content */}

            <div className="flex flex-col justify-center items-center mt-14 md:mt-8 text-sm text-gray-400/90 font-semibold border-t-[3px] border-gray-200 pt-3">
                    <span>{t('home_page.courtesy')}</span>
                    <span>{t('home_page.web')}</span>
                    <span>{t('home_page.email')}</span>
                    <span>{t('home_page.copyright')}</span>
            </div>
        </div>
      </div>

  );
  
};


export {TermsAndConditions};
