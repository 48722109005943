import { useState, useEffect, useLayoutEffect, useRef } from 'react';
import Chart from 'react-apexcharts';

import { Footer, SelectInputV2, TableDAtaView, Spinner} from '../../components';
import './reportStyle.css';

import { useHandleSubmit, useEnaDisableStyle } from '../../hooks';
import { useEnv } from '../../contexts';
import { getResource } from '../../services/farmApi';

import { useTranslation } from "react-i18next";

function WeighingsReport() {

    // for language translation
    const { t } = useTranslation();

    const [report, setReport] = useState({filter: t('search_page.select_report')});
    const [isSearching, setIsSearching] = useState(false);
    const [weighingData, setWeighingData] = useState([]);
    const [weighingViewData, setWeighingViewData] = useState([]);
    const [tableSettings, setTableSettings] = useState({idUsage: 1, useSerial: true}); // Influence how table is displayed
    const initNotice = useRef({
        msg: "",
        showNotice: false,
        succ: false,
        status: null,
        ver: null   
    });
    const [createNotice, setCreateNotice] = useState(initNotice.current);
    const initChartState = useRef({
        options: {
          chart: {
            id: "basic-line",
            zoom: {
                enabled: false
            }
          },
        //   grid: {
        //     padding: {left: 180}
        //   },
          xaxis: {
            categories: []
          }
        },
        series: [
          {
            name: t('milking_page.am'), 
            data: []
          },
          {
            name: t('milking_page.noon'),
            data: []
          },
          {
            name: t('milking_page.pm'),
            data: []
          }
        ]
      })
    const [chartState, setChartState] = useState(initChartState.current);
    
    const { handleGetResource } = useHandleSubmit(); // Shared submit handler
    const { envState, setEnvState} = useEnv(); // Get Environment context

    // Filter Select options
    const reportOptions = useRef([
        ["default", t('search_page.select_report')],
        ["latest", t('search_page.latest_weight')],
        ["trend", t('search_page.year_trend')],
    ]);

    // console.log("Events Report: whats in the data", weighingData);
    const textInputStyle = useEnaDisableStyle();

    /**
     * Notifiy the App of this location, provide Header Nav links
     *
     */
    useEffect(() => {
        setEnvState(state => ({...state, whereAbout: t('navigation_element.nav_item_title_weight_report'), backPath: "/fe/reports", homePath: "/fe"}));

        return () => {
            setEnvState(state => ({...state, whereAbout: "", backPath: "", homePath: ""}));
        }
        // eslint-disable-next-line
    }, []);

    // Handle Select input change
    const handleReportChange = (inputKey, inputValue) => {
        setReport({[inputKey]: inputValue});
    }

    // Fetch report
    useLayoutEffect(() => {
        // Handle report report
        const handleFilterChange = (filter, idUsage = 1, useSerial = true) => {
            console.log("got filer:", filter);
            // Query the report
            getWeighingReport("/?filter=" + filter);
            // Make any required changes
            setTableSettings({idUsage, useSerial});
        }

        // Handle clear report filter
        const handleReset = () => {
            weighingViewData.length > 0 && setWeighingViewData([]);
            weighingData.length > 0 && setWeighingData([]);
        }

        const getWeighingReport = (reportQuery) => {
            handleGetResource(
                null,
                getResource,
                envState.online,
                process.env.REACT_APP_WEIGHINGS + reportQuery,
                setCreateNotice,
                null,
                setWeighingData,
                setIsSearching,
                false // explicitly expecting a single model?
            );
        }

        switch (report.filter) {
            case reportOptions.current[1][0]:
                handleFilterChange(reportOptions.current[1][0]);
                break;
            case reportOptions.current[2][0]:
                handleFilterChange(reportOptions.current[2][0]);
                break;

            default:
                handleReset();
                break;
        }
        // eslint-disable-next-line
    }, [report]);

    // Display report
    useLayoutEffect(() => {
        // Weighing data was uniquely packaged
        if (weighingData.length > 0) {
            if (report.filter === 'trend') { // Branching out to show chart
                // Extract categories (x-axis labels), AM, Noon and PM litres
                console.log('x:', weighingData[0].dates);
                // const xLabels = [];

                const weightSeries = weighingData[0].ids.map(cattle_id => ({
                    name: cattle_id,
                    data: weighingData[0][cattle_id]
                }));
                setChartState(currentState => ({
                    ...currentState,
                    options: {
                        chart: currentState.options.chart,
                        xaxis: {
                            categories: weighingData[0].dates
                        }
                    },
                    series: weightSeries
                }));

            } else {
                setChartState(initChartState.current);
                // console.log("about to post", weighingData);
                setWeighingViewData(weighingData);
            }         
        } else {
            setWeighingViewData([]);
            setChartState(initChartState.current);
        }
        // eslint-disable-next-line
    }, [weighingData]);

    // handle reset report selection
    useLayoutEffect(() => {
        if (createNotice.status !== 200 && createNotice.status !== 404) {
            setReport({filter: reportOptions.current[0][1]}); 
        }
    }, [createNotice.ver, createNotice.status])

    // console.log("this is wierd", chartState);

    // ----------------------------------
    //       Below is the original source code, commented out one <div to fix horinzontal scroll which wasn't working
    //       I added overflow-x-auto to the container to enable horizontal scrolling.
    //       I adjusted the overflow property as needed based on layout requirements.
    //       Ensure that the content inside the container does not have a fixed width that exceeds the width of the container.
    // ----------------------------------  

    return (
        <div className="md:grid md:grid-cols-2 md:gap-6 mt-[100px] mb-[100px] overflow-x-auto">
            <div className="col-span-2 flex flex-col justify-center items-center">
                <form autoComplete="off" className="w-full">
                    {/*<div className="overflow-hidden w-full">*/}
                        <div className="py-5">

                            <div className="grid grid-cols-6 lg:grid-cols-5">
                                <div className="hidden lg:block lg:col-span-1">{/*spacer*/}</div>
                                {/* Report - select */}
                                <div className="col-span-6 lg:col-span-3 flex justify-center p-2">
                                    <div className="w-[400px]">
                                        {/* <InputLabel
                                            inputId="report"
                                            inputStyle="block text-sm font-medium text-gray-700"
                                            labelText="Select Cattle Report"
                                        /> */}
                                        <SelectInputV2
                                            focus={false}
                                            inputName="filter"
                                            inputId="report"
                                            inputStyle={textInputStyle.current.enabledInput}
                                            inputValue={report.filter}
                                            handleChange={handleReportChange}
                                            choices={reportOptions.current}      
                                        />
                                    </div>
                                </div>
                                <div className="hidden lg:block lg:col-span-1">{/*spacer*/}</div>
                            </div>

                            { isSearching &&
                                <div className="grid grid-cols-6 lg:grid-cols-5">
                                    <div className="col-span-6 lg:col-span-5 flex justify-center p-2">
                                        <Spinner /> 
                                    </div>
                                </div>
                            }   
                            {
                                createNotice.succ ?
                                    report.filter === "trend" ?
                                        <div className="grid grid-cols-12 mt-6">
                                            <div className="col-span-1">{/*spacer*/}</div>
                                            <div className="col-span-10 border-[2px] border-gray-200 rounded-lg">
                                            <div className="w-full flex justify-center p-2">
                                                <div className="overflow-x-auto frame">
                                                    <div className="w-full">
                                                        <Chart
                                                            type='line'
                                                            width={Math.ceil(chartState.series[0].data.length / 10) * 500}
                                                            height='300'
                                                            options={chartState.options}
                                                            series={chartState.series}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="col-span-1">{/*spacer*/}</div>
                                        </div>:
                                    <div className="grid grid-cols-6 lg:grid-cols-5">
                                        <div className="hidden lg:block lg:col-span-1">{/*spacer*/}</div>
                                        <div className="col-span-6 lg:col-span-3">
                                        <div className="w-full flex justify-center p-2">
                                            <TableDAtaView
                                                tableData={weighingViewData}
                                                idUsage={tableSettings.idUsage}
                                                useSerial={tableSettings.useSerial}
                                                mapHash={{
                                                    "#": "#",
                                                    cattle_name: t("cattle_page.name"), 
                                                    //girth_length: t('table_formatter.table_girth_length'),
                                                    weight: t('table_formatter.table_weight'),
                                                    check_date: t('table_formatter.table_weighed'),
                                                    date: t('table_formatter.table_date'),
                                                }}
                                            />
                                        </div>
                                        </div>
                                        <div className="hidden lg:block lg:col-span-1">{/*spacer*/}</div>
                                    </div> :
                                createNotice.status === 404 ?
                                    <div className="grid grid-cols-6 lg:grid-cols-5">
                                        <div className="hidden lg:block lg:col-span-1">{/*spacer*/}</div>
                                        <div className="col-span-6 lg:col-span-3">
                                        <div className="w-full flex justify-center py-2">
                                            <span> {t('user_handle_notice.no_data_matching_criteria')}</span>
                                        </div>
                                        </div>
                                        <div className="hidden lg:block lg:col-span-1">{/*spacer*/}</div>
                                    </div> :
                                ""
                            }
                        </div>
                        <Footer respNotice={createNotice} setRespNotice={setCreateNotice} backLink="/fe/reports" footerType="nav" />
                    {/*</div>*/}
                </form>
            </div>  
        </div>
    );
}

export { WeighingsReport };