import { useState, useEffect, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Footer, SelectInput, TableDAtaView, EditDelTableDataView } from '../../components';
import { ArrowPathIcon } from '../../icons/';

import { useHandleSubmit, useEnaDisableStyle } from '../../hooks';
import { useEnv } from '../../contexts';
import { getResource } from '../../services/farmApi';

import { useTranslation } from "react-i18next";

import { CattleForm } from "../Cattle";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function CattleReport() {

    // for language translation
    const { t } = useTranslation();

    const navigate = useNavigate();
    const [report, setReport] = useState({filter: ""});
    // const [isSearching, setIsSearching] = useState(false);
    const [cattleData, setCattleData] = useState([]);
    const [cattleViewData, setCattleViewData] = useState([]);
    const [cattleEditViewData, setCattleEditViewData] = useState([]);
    const [createNotice, setCreateNotice] = useState({
        msg: "",
        showNotice: false,
        succ: false,
        status: null,
    });
    const [tableSettings, setTableSettings] = useState({idUsage: 1, useSerial: true}); // Influence how table is displayed
    const { handleGetResource, handleSubmit } = useHandleSubmit(); // Shared submit handler
    const { envState, setEnvState} = useEnv(); // Get Environment context

    const textInputStyle = useEnaDisableStyle();
    // ID for the currently selected record in Edit and Delete table
    const [selectedId, setSelectedId] = useState(0);

    // Foot Type; nav -> default, editDelete -> to show edit and delete buttons
    const [footerType, setFooterType] = useState("nav");
    const [showCattleForm, setShowCattleForm] = useState(false);
    const [cattleRecord, setCattleRecord] = useState({});

    const showToast = (message, type) => {
        toast[type](message, {
          position: "top-center", 
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
    };

    /**
     * Notifiy the App of this location, provide Header Nav links
     * Fetch resource
     */
    useEffect(() => {
        setEnvState(state => ({...state, whereAbout: t('navigation_element.nav_item_title_cattle_report'), backPath: "/fe/reports", homePath: "/fe"}));

        //! createNotice.succ &&
        handleGetResource(
            null,
            getResource,
            envState.online,
            process.env.REACT_APP_CATTLE,
            setCreateNotice,
            null,
            setCattleData,
            null,
            false // explicitly expecting a single model?
        );
        return () => {
            setEnvState(state => ({...state, whereAbout: "", backPath: "", homePath: ""}));
        }
        // eslint-disable-next-line
    }, [footerType]);

    // Handle Report change
    const handleReportChange = (inputKey, inputValue) => {
        setReport({[inputKey]: inputValue});
    }

    // Handle Radio's input record selection on Edit and Delete table
    const handleOnSelectId = (inputKey, inputValue) => {

        // Check if the radio button is clicked
        if (inputKey === 'selectedId') {
        } else {
            // When the row is clicked, swap inputKey and inputValue
            inputValue = inputKey;
            inputKey = 'selectedId';
        }

        // Always set inputKey to 'selectedId'
        inputKey = 'selectedId';

        setSelectedId(inputValue);
    };    

    // handle Form Reload
    const handleReload = () => {
        navigate(0);
    }

    // handle form for edit cattle record
    const handleEdit = (e) => {
        e.preventDefault();
        if (selectedId) {
            // Ensure selectedId and record.id are of the same type
            const foundRecord = cattleData.find(record => String(record.id) === String(selectedId));
    
            if (foundRecord) {
                // Add the refId property to the foundRecord to be used later as variable user can't change
                const updatedRecord = { ...foundRecord, refId: foundRecord.id };
                setCattleRecord(updatedRecord);
            } else {
                setCattleRecord({});
            }
            
            setShowCattleForm(true);
        } else {
            showToast(t("validation_messages.select_record_first"), "info"); 
        }
    }

    // handle form for cancel intention to edit cattle record
    const handleCancel = (e) => {
        e.preventDefault();
        setShowCattleForm(false);
        navigate('/fe/reports');
    }

    useLayoutEffect(() => {
        // {console.log('data is here: ', cattleViewData)}
        // Handle Summary
        const handleSummary = () => {
            // Select required fields
            const summaryData = cattleData.map(row => ({
                id: row.id,
                [t('cattle_page.old_tag')] : row.Old_Tag, 
                [t('table_formatter.table_name')]   : row.Name,
                [t('table_formatter.table_breed')]    : row.Breed,
                [t('table_formatter.table_gender')]   : row.Gender,
                [t('table_formatter.table_dob')] : row.DoB,
                [t('table_formatter.table_weight')]: row.LatestWeight,
                [t('table_formatter.table_daily_milk')] : row.AverageMilkVolume,
            }));
            setCattleViewData(summaryData);
        }
        // Handle Gender
        const handleGender = () => {
            let female = 0;
            let male = 0;
            // Count Female and Male cattle
            cattleData.forEach(row => {
                if (row.Gender === "Female") {
                    female++;
                } else if (row.Gender === "Male") {
                    male++;
                }
            });

            const genderData = []; // index: 0 -> Female, 1 -> Male
            if (male || female) {
              genderData.push({
                id: 0,
                [t('table_formatter.table_gender')]: t('cattle_page.female'),
                [t('table_formatter.table_count')]: female
              }, {
                id: 1,
                [t('table_formatter.table_gender')]: t('cattle_page.male'),
                [t('table_formatter.table_count')]: male
              });
            }
            setTableSettings({ idUsage: 0, useSerial: true });
            setCattleViewData(genderData);
        }
        // Handle Breed
        const handleBreed = () => {
            const availableBreed = [];
            let breedData = [];
            const breedHash = {};
            cattleData.forEach(row => {
                if (breedHash[row.Breed] !== undefined) {
                    breedHash[row.Breed]++;
                } else {
                    breedHash[row.Breed] = 1;
                    availableBreed.push(row.Breed);
                }
            });

            breedData = availableBreed.map((breed, index)=> ({id: index, [t('table_formatter.table_breed')]: breed, [t('table_formatter.table_count')]: breedHash[breed]}));
            setTableSettings({idUsage: 0, useSerial: true});
            setCattleViewData(breedData);
        }
        // Handle Source Type
        const handleSource = () => {
            let internal = 0;
            let external = 0;
            // Count Internal and External cattle
            cattleData.forEach(row => {
                if (row.Source_Type === "Internal") {
                    internal++;
                } else if (row.Source_Type === "External") {
                    external++;
                }
            });

            const sourceData = [];
            if (external || internal) {
              sourceData.push({
                id: 0,
                [t('table_formatter.table_source')]: t('cattle_page.internal'),
                [t('table_formatter.table_count')]: internal
              }, {
                id: 1,
                [t('table_formatter.table_source')]: t('cattle_page.external'),
                [t('table_formatter.table_count')]: external
              });
            }
            setTableSettings({ idUsage: 0, useSerial: true });
            setCattleViewData(sourceData);
    
        }

        // Handle Cattle Edit
        const handleCattle = () => {
            // Select required fields to display edit record
            const summaryEditData = cattleData.map(row => ({
                id: row.id,
                "Tag": row.id,
                "Breed"   : row.Breed,
                "Name"   : row.Name,
                "Gender"   : row.Gender,
                "DoB" : row.DoB,
                "Weight" : row.Weight,
                "OldTag" : row.Old_Tag,                                    
                "Recorded" : row.Recorded.substring(0, 10)                                  
            }));
            setCattleEditViewData(summaryEditData);
        }

        // Handle clear report
        const handleReset = () => {
            setCattleViewData([]);
        }

        switch (report.filter) {
            case "Summary":
                handleSummary();
                break;
            case "Gender":
                handleGender();
                break;
            case "Breed":
                handleBreed();
                break;
            case "Source_Type":
                handleSource();
                break;
            case "UpdateCattle":
                handleCattle();
                break;
            default:
                handleReset();
                break;
        }
        // eslint-disable-next-line
    }, [report]);

    // ----------------------------------
    //       Below is the original source code, commented out one <div to fix horinzontal scroll which wasn't working
    //       I added overflow-x-auto to the container to enable horizontal scrolling.
    //       I adjusted the overflow property as needed based on layout requirements.
    //       Ensure that the content inside the container does not have a fixed width that exceeds the width of the container.
    // ----------------------------------
    if (!showCattleForm) {
        return (
            <div className="md:grid md:grid-cols-2 md:gap-6 mt-[100px] mb-[100px] overflow-x-auto">
                <div className="col-span-2 flex flex-col justify-center items-center">

                    <form autoComplete="off" className="w-full">
                        {/*<div className="overflow-hidden w-full">*/}
                            <div className="py-5">
                                <div className="grid grid-cols-6 lg:grid-cols-5">
                                    <div className="hidden lg:block lg:col-span-1">
                                        {/*spacer*/}
                                    </div>
                                    
                                    {/* { isSearching && 
                                        <div className="col-span-4 lg:col-span-3 flex justify-center mt-12">
                                            <Spinner /> 
                                        </div>
                                    } */}
                                        
                                    { 
                                        createNotice.succ ?
                                            <div className="col-span-6 lg:col-span-3">
                                                <div className="mb-[10px] rounded-sm w-full text-lg flex justify-center p-2">
                                                    <span className="text-gray-500">{t('search_page.total_number_of_cattle')}</span>
                                                    <span className="font-semibold">{cattleData.length}</span>
                                                </div>
                                                {/* Report - select */}
                                                <div className="w-full flex justify-center p-2">
                                                    <div className="w-[400px]">
                                                        <SelectInput
                                                            focus={false}
                                                            inputName="filter"
                                                            inputId="report"
                                                            inputStyle={textInputStyle.current.enabledInput}
                                                            inputValue={report.filter}
                                                            handleChange={handleReportChange}
                                                            choices={[
                                                                {key: 'default', label: t('search_page.select_report') },
                                                                {key: 'Summary', label: t('search_page.summary') },
                                                                {key: 'Gender', label: t('search_page.gender') },
                                                                {key: 'Breed', label: t('search_page.breed') },
                                                                {key: 'Source_Type', label: t('search_page.source') },
                                                                {key: 'UpdateCattle', label: t('navigation_element.nav_item_title_update_cattle') },  
                                                            ]}
                                                            
                                                        />
                                                    </div>
                                                </div>
                                                <div className="w-full flex justify-center py-2" style={{ marginLeft: report.filter === 'Summary' ? '1rem' : '0rem' }}>
                                                    {report.filter !== "UpdateCattle" ? (
                                                        <TableDAtaView tableData={cattleViewData} idUsage={tableSettings.idUsage} useSerial={tableSettings.useSerial}/>
                                                        
                                                    ) : (
                                                        <EditDelTableDataView   
                                                            tableData={cattleEditViewData}
                                                            idUsage={0}
                                                            useSerial={tableSettings.useSerial}
                                                            mapHash={{
                                                                "#": "#",
                                                                Tag: t('table_formatter.table_tag'),   
                                                                Breed: t('cattle_page.breed'), 
                                                                Name: t('table_formatter.table_name'),    
                                                                Gender: t('cattle_page.gender'),   
                                                                DoB: t('table_formatter.table_dob'),    
                                                                Weight: t('cattle_page.weight'),   
                                                                OldTag: t('cattle_page.old_tag'),   
                                                                Recorded: t('cattle_page.cattle_recorded'),  
                                                            }}
                                                            selectedId={selectedId}
                                                            onSelectId={handleOnSelectId}
                                                        />
                                                    )}
                                                </div>
                                            </div> :
                                        <div className="col-span-6 flex justify-center">
                                            <div onClick={handleReload} className="cursor-pointer w-[160px] py-2 px-3 mt-12 bg-gray-50 hover:bg-gray-200 rounded-lg border-2 border-gray-400 flex justify-center items-center gap-3">
                                                <ArrowPathIcon />
                                                <span className="font-semibold">{t('navigation_element.nav_item_title_retry')}</span>
                                            </div>
                                        </div>
                                    }

                                    <div className="hidden lg:block lg:col-span-1">{/*spacer*/}</div>
                                </div>
                            </div>
                            {report.filter !== "UpdateCattle" ? (
                                // nav footer type
                                <Footer respNotice={createNotice} setRespNotice={setCreateNotice} backLink="/fe/reports" footerType="nav" />
                            ):(
                                //  edit footer type
                                <Footer
                                    respNotice={createNotice} setRespNotice={setCreateNotice} backLink="/fe/reports" footerType={'edit'} onEdit={handleEdit} onCancel={handleCancel}
                            />                           
                            )}
                        {/*</div>*/}
                    </form>
                </div>  
                <div>
                {/* Toast Container */}
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    closeOnClick
                    pauseOnHover
                    draggable
                    pauseOnFocusLoss
                    theme="light"
                />
                </div>
            </div>
        );
    } else {
        return (
            <div className="mt-[100px] mb-[100px]">
              <CattleForm
                cattleData={cattleRecord}
                formMode={"edit"}
              />
            </div>
        );        
    }
}

export { CattleReport };