import { useRef } from "react";
import { RadioInput } from "./forms/RadioInput";

// This Component is a variant of TableDataView
// tailored for tabular Edit/Delete form
// Make sure tableData is null or has data in it
export const EditDelTableDataView = (props) => {
  /**
   * Note: props.idUsage
   * 0 = ignore id attribute
   * 1 = use id attribute, rename it to Tag No.
   * 2 = use id attribute, rename it to ID
   */
  let headerLabels = useRef([]);
  let headerData = useRef([]);
  let tableRowData = useRef([]);

  if (props.tableData.length === 0) {
    // console.log("no data, no header => no report");
    return (
      <div className="py-8 border-dotted border-b-2 border-gray-400">
        {/* <span className="text-lg font-semibold"> No data found</span> */}
      </div>
    );
  } else {
    // Extract properties as  header-Labels array
    headerLabels.current = Object.keys(props.tableData[0]);

    // props.useSerial === true, add Serial no on the table.
    props.useSerial && headerLabels.current.unshift("#");

    if (props.idUsage === 0) {
      // Remove id (extracted property) from the table headers array
      headerData.current = headerLabels.current.reduce((acc, cur) => {
        // if mapHash contains label transformation
        let headerTitle = props.mapHash ? props.mapHash[cur] : cur;

        return cur !== "id"
          ? [
              ...acc,
              <th
                key={cur}
                className={
                  headerTitle.length > 11
                    ? "py-1 px-2 text-left"
                    : "whitespace-nowrap py-1 px-2 text-left"
                }
              >
                {headerTitle}
              </th>,
            ]
          : acc;
      }, []);

      // Insert empty header for Radio buttons
      headerData.current.unshift(
        <th
          key={"emptyHeaderForRadio"}
          className="whitespace-nowrap py-1 px-2 text-left"
        >
          {/* Empty Header for Radio Buttons */}
          {""}
        </th>
      );

      // Likewise exclude id propery value from table data
      // Note this is assumed to be an acceptable big o(n2)
      tableRowData.current = props.tableData.map((tableRow, index) => {
        return (
          // <tr key={tableRow?.id} className="divide-x-[2px] divide-gray-300 odd:bg-gray-200">
          <tr
            key={tableRow?.id + index}
            className="border-b last:border-none border-gray-300 hover:bg-gray-300"
            onClick={() => props.onSelectId(tableRow?.id)}
          >
            <>
              {/* Insert the radio button */}
              <td key={"radioButton" + tableRow?.id} className="py-1 px-2">
                <RadioInput
                  focus={false}
                  inputName="selectedId"
                  inputId={"selectedId" + index}
                  inputStyle="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  inputValue={tableRow?.id}
                  inputStateValue={props.selectedId}
                  handleChange={props.onSelectId}
                />
              </td>
              {
                // reducing into an array, while skipping id and inserting serial number if # labels exists => props.useSerial
                headerLabels.current.reduce((acc, cur) => {
                  return cur !== "id"
                    ? [
                        ...acc,
                        <td
                          key={tableRow?.id + cur}
                          className={
                            cur !== "#"
                              ? (tableRow[cur] + "").length > 11
                                ? "py-1 px-2"
                                : "whitespace-nowrap py-1 px-2"
                              : "py-1 px-2"
                          }
                        >
                          {cur !== "#" ? tableRow[cur] : index + 1}
                        </td>,
                      ]
                    : acc;
                }, [])
              }
            </>
          </tr>
        );
      });
    } else {
      if (props.idUsage === 1) {
        // Rename id (extracted property) to Tag No.
        headerData.current = headerLabels.current.map((cur) => {
          let headerTitle = "";
          if (cur === "id") {
            headerTitle = "Tag #";
          } else {
            // mapHash contains label transformation
            headerTitle = props.mapHash ? props.mapHash[cur] : cur;
          }
          // console.log("kichwa", headerTitle);
          return (
            <th
              key={cur}
              className={
                headerTitle.length > 11
                  ? "py-1 px-2 text-left"
                  : "whitespace-nowrap py-1 px-2 text-left"
              }
            >
              {headerTitle}
            </th>
          );
        });

        // Insert empty header for Radio buttons
        headerData.current.unshift(
          <th
            key={"emptyHeaderForRadio"}
            className="whitespace-nowrap py-1 px-2 text-left"
          >
            {/* Empty Header for Radio Buttons */}
            {""}
          </th>
        );
      } else {
        // Capitalize id (extracted property) to ID.
        headerData.current = headerLabels.current.map((cur) => {
          let headerTitle = "";
          if (cur === "id") {
            headerTitle = "ID";
          } else {
            // mapHash contains label transformation
            headerTitle = props.mapHash ? props.mapHash[cur] : cur;
          }

          return (
            <th
              key={cur}
              className={
                headerTitle.length > 11
                  ? "py-1 px-2 text-left"
                  : "whitespace-nowrap py-1 px-2 text-left"
              }
            >
              {headerTitle}
            </th>
          );
        });
        // Insert empty header for Radio buttons
        headerData.current.unshift(
          <th
            key={"emptyHeaderForRadio"}
            className="whitespace-nowrap py-1 px-2 text-left"
          >
            {/* Empty Header for Radio Buttons */}
            {""}
          </th>
        );
      }
      // In either of the above two conditions create table data in the same way
      // Note this is assumed to be an acceptable big o(n2)
      tableRowData.current = props.tableData.map((tableRow, index) => {
        return (
          // <tr key={tableRow?.id} className="divide-x-[2px] divide-gray-300 odd:bg-gray-200">
          <tr
            key={tableRow?.id + index}
            className="border-b last:border-none border-gray-300 hover:bg-gray-300"
            onClick={() => props.onSelectId(tableRow?.id)}
          >
            <>
              {/* Insert the radio button */}
              <td key={"radioButton" + tableRow?.id} className="py-1 px-2">
                <RadioInput
                  focus={false}
                  inputName="selectedId"
                  inputId={"selectedId" + index}
                  inputStyle="h-4 w-4 border-gray-900 text-indigo-600 focus:ring-indigo-500"
                  inputValue={tableRow?.id}
                  inputStateValue={props.selectedId}
                  handleChange={props.onSelectId}
                />
              </td>
              {
                // reducing into an array
                headerLabels.current.map((cur) => {
                  return (
                    <td
                      key={tableRow?.id + cur}
                      className={
                        (tableRow[cur] + "").length > 11
                          ? "py-2 px-2"
                          : "whitespace-nowrap py-2 px-2"
                      }
                    >
                      {cur !== "#"
                        ? tableRow[cur]
                          ? tableRow[cur]
                          : "-"
                        : index + 1}
                    </td>
                  );
                })
              }
            </>
          </tr>
        );
      });
    }

    return (
      <div className="mt-6 p-3 border-[2px] border-gray-300 rounded-lg bg-gray-50 overflow-x-auto">
        <div className="max-w-full overflow-x-auto">
          <table className="table-auto text-gray-600">
              <thead>
                <tr className="border-b border-gray-300 hover:bg-gray-300">
                    {headerData.current}
                </tr>
              </thead>
              <tbody>
                {tableRowData.current}                
              </tbody>
          </table>
        </div>
      </div>




    );
  }
};
