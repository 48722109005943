import { memo } from "react";
import { XMarkIcon } from "../icons";

import { useTranslation } from "react-i18next";

/**
 * Footer with Submit button and togglable Notice banner
 * @param {props.respNotice} Object with form submission status and flag for hidding or showing notice
 * @param {props.setRespNotice} Function for editing respNotice
 * @param {props.footerType} String representing the footer's button type
 *
 */
export const Footer = memo(function Footer(props) {
  // for language translation
  const { t } = useTranslation();

  // console.log("notice in footer:", props.respNotice);
  // console.log("notice in footer:", Math.random()*10);
  // this is a fixed footer, you may change if you want 
  const noticeClass =
    props.respNotice.status === 200
      ? "border border-blue-200 p-2 sm:px-6 grid grid-cols-12 border-l-8 border-green-400 h-16 bg-green-200"
      : props.respNotice.status === 404
      ? "border border-blue-200 p-2 sm:px-6 grid grid-cols-12 border-l-8 border-blue-400 h-16 bg-blue-200"
      : "border border-red-200 p-2 sm:px-6 grid grid-cols-12 border-l-8 border-red-400 h-16 bg-red-200";

  const submitResp = props.respNotice.showNotice ? (
    <div className={noticeClass}>
      <div className="flex flex-col justify-center items-center col-span-11">
        <span className="font-bold">
          {props.respNotice.succ
            ? t("table_formatter.table_success")
            : props.respNotice.status === 404
            ? t("table_formatter.table_info")
            : t("table_formatter.table_error")}
        </span>
        <span>{props.respNotice.msg}</span>
      </div>
      <div
        className="flex justify-center items-center col-span-1"
        onClick={() => {
          props.setRespNotice({ showNotice: false, succ: "", msg: "" });
        }}
      >
        <XMarkIcon />
      </div>
    </div>
  ) : (
    ""
  );
  const PasswordResetControl =
    props.footerType === "resetPassword" ? (
      <div className="w-full flex items-center justify-center bg-slate-100 px-4 py-3">
        <button
          type="submit"
          disabled={props.respNotice?.isSubmitting}
          className="w-[300px] inline-flex justify-center rounded-md border border-transparent bg-slate-600 py-2 px-4 text-sm font-semibold text-gray-200 shadow-sm hover:bg-slate-500 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2 uppercase"
        >
          {props.respNotice?.isSubmitting ? (
            <div className="h-6 w-6 border-t-green-400 border-l-green-200 border-4 border-solid rounded-full animate-spin"></div>
          ) : (
            t('buttons_navigation.button_nav_submit')
          )}
        </button>
      </div>
    ) : (
      ""
    );

    const RecordUpdateControl =
    props.footerType === "updaterecord" ? (
      <div className="w-full flex items-center justify-center bg-slate-100 px-4 py-3">
        <button
          type="submit"
          disabled={props.respNotice?.isSubmitting}
          className="w-[300px] inline-flex justify-center rounded-md border border-transparent bg-slate-600 py-2 px-4 text-sm font-semibold text-gray-200 shadow-sm hover:bg-slate-500 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2 uppercase"
        >
          {props.respNotice?.isSubmitting ? (
            <div className="h-6 w-6 border-t-green-400 border-l-green-200 border-4 border-solid rounded-full animate-spin"></div>
          ) : (
            t('buttons_navigation.button_nav_submit')
          )}
        </button>
      </div>
    ) : (
      ""
    );

    const RecordTypeUpdateControl =
    props.footerType === "update" ? (
      <div className="w-full flex items-center justify-center bg-slate-100 px-4 py-3">
        <button
          type="submit"
          disabled={props.respNotice?.isSubmitting}
          className="w-[300px] inline-flex justify-center rounded-md border border-transparent bg-slate-600 py-2 px-4 text-sm font-semibold text-gray-200 shadow-sm hover:bg-slate-500 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2 uppercase"
        >
          {props.respNotice?.isSubmitting ? (
            <div className="h-6 w-6 border-t-green-400 border-l-green-200 border-4 border-solid rounded-full animate-spin"></div>
          ) : (
            t('buttons_navigation.buttons_navigation_update')
          )}
        </button>
      </div>
    ) : (
      ""
    );
  
  const createFooter =
    props.footerType === "create" ? (
      <div className="w-full flex items-center justify-center bg-slate-100 px-4 py-3">
        <button
          type="submit"
          disabled={props.respNotice?.isSubmitting}
          className="w-[300px] inline-flex justify-center rounded-md border border-transparent bg-slate-600 py-2 px-4 text-sm font-semibold text-gray-200 shadow-sm hover:bg-slate-500 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2 uppercase"
        >
          {props.respNotice?.isSubmitting ? (
            <div className="h-6 w-6 border-t-green-400 border-l-green-200 border-4 border-solid rounded-full animate-spin"></div>
          ) : (
            t("buttons_navigation.button_nav_create_record")
          )}
        </button>
      </div>
    ) : (
      ""
    );
  const EditDeleteControls =
    props.footerType === "editDelete" ? (
      <div className="p-4 flex gap-x-2 justify-center items-center  border border-black">
        <button
          type="submit"
          disabled={props.respNotice?.isSubmitting}
          onClick={props?.onEdit}
          className="w-[200px] inline-flex justify-center rounded-md border border-transparent bg-slate-600 py-2 px-4 text-sm font-semibold text-gray-200 shadow-sm hover:bg-slate-500 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2 uppercase"
        >
          {t("buttons_navigation.buttons_navigation_edit")}
        </button>
        <button
          type="submit"
          disabled={props.respNotice?.isSubmitting}
          onClick={props?.onDelete}
          className="w-[200px] inline-flex justify-center rounded-md border border-transparent bg-slate-600 py-2 px-4 text-sm font-semibold text-gray-200 shadow-sm hover:bg-slate-500 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2 uppercase"
        >
          {t("buttons_navigation.buttons_navigation_delete")} 
        </button>
      </div>
    ) : (
      ""
    );
    const ActiveDeactivateControls =
    props.footerType === "activeDeactive" ? (
      <div className="p-4 flex gap-x-2 justify-center items-center  border border-black">
        <button
          type="submit"
          disabled={props.respNotice?.isSubmitting}
          onClick={props?.onActivate}
          className="w-[200px] inline-flex justify-center rounded-md border border-transparent bg-slate-600 py-2 px-4 text-sm font-semibold text-gray-200 shadow-sm hover:bg-slate-500 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2 uppercase"
        >
          {t("buttons_navigation.button_activate")}
        </button>
        <button
          type="submit"
          disabled={props.respNotice?.isSubmitting}
          onClick={props?.onDeactivate}
          className="w-[200px] inline-flex justify-center rounded-md border border-transparent bg-slate-600 py-2 px-4 text-sm font-semibold text-gray-200 shadow-sm hover:bg-slate-500 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2 uppercase"
        >
          {t("buttons_navigation.button_deactivate")} 
        </button>
      </div>
    ) : (
      ""
    );
  const EditControls =
    props.footerType === "edit" ? (
      <div className="p-4 flex gap-x-2 justify-center items-center">
        <button
          type="submit"
          disabled={props.respNotice?.isSubmitting}
          onClick={(e) => {
            // e.preventDefault();
            // console.log(
            //   "from footer",
            //   props.editableRecord,
            //   props.editableRecordId
            // );
            props.onEdit(e, props.editableRecord, props.editableRecordId);
          }}
          className="w-[200px] inline-flex justify-center rounded-md border border-transparent bg-slate-600 py-2 px-4 text-sm font-semibold text-gray-200 shadow-sm hover:bg-slate-500 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2 uppercase"
        >
          {t("buttons_navigation.buttons_navigation_update")}
        </button>
        <button
          type="button"
          disabled={props.respNotice?.isSubmitting}
          onClick={props?.onCancel}
          className="w-[200px] inline-flex justify-center rounded-md border border-transparent bg-slate-600 py-2 px-4 text-sm font-semibold text-gray-200 shadow-sm hover:bg-slate-500 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2 uppercase"
        >
          {t("buttons_navigation.buttons_navigation_cancel")}
        </button>
      </div>
    ) : (
      ""
    );

  return (
    <footer className="w-screen fixed bottom-0">
      {RecordUpdateControl}
      {RecordTypeUpdateControl}
      {PasswordResetControl}
      {submitResp}
      {EditControls}
      {EditDeleteControls}
      {ActiveDeactivateControls}
      {createFooter}
    </footer>
  );
});
