import { useState } from "react";

import { guestCreateResource } from "../../services/farmApi";

import { ArrowLongLeftIcon, LockSolidOpenedIcon } from "../../icons";
import logo from "../../icons/gdf192.png";
import { Spinner } from "../Spinner";

import { useTranslation } from "react-i18next";

// Forgot Password Dialog
const ForgotPass = ({ onChangeDialog }) => {
  const [email, setEmail] = useState("");
  const [reqError, setReqError] = useState("");
  const [revealResp, setRevealResp] = useState("");
  const [shouldSpin, setShouldSpin] = useState(false);

  const handleForgotPass = async (e) => {
    e.preventDefault();
    if (email === "") {
      //setReqError("Email is required");
      setReqError(t('error_message.email_required'));
    } else {
      // console.log("depositing", process.env.REACT_APP_AUTH_PASSWORD_RESET);
      // Fire spinner
      setShouldSpin(true);
      const reqResp = await guestCreateResource(
        process.env.REACT_APP_AUTH_PASSWORD_RESET,
        { email }, 
        t
      );
      //console.log('setting user data', reqResp);
      if (reqResp.success) {
        setReqError("");
        // Stop spinner
        setShouldSpin(false);
        // Show Notificaton screen
        setRevealResp(reqResp.message);
      } else {
        // Show error on register dialog
        // if (reqResp.status === 101) {
        //   setReqError("Wrong Email or Password");
        // } else {
        //   setReqError("Login failed. Try again later.");
        // }
        setReqError(reqResp?.message);
        // Stop spinner
        setShouldSpin(false);
        // console.log("Got farm reg error", reqResp);
      }
    }
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  // Activate Register dialog
  const handleLogin = () => {
    onChangeDialog("landing");
  };

  // for language translation
  const { t } = useTranslation();

  return (
    <>

      {/* nav */}
      <div className="w-full flex justify-center px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md">
          <div className="flex justify-start pt-2">
            <div
              className="flex gap-2 mt-5 cursor-pointer border border-gray-300 rounded-full pl-2 pr-4 py-1"
              onClick={handleLogin}
            >
              <ArrowLongLeftIcon />
              <span>{t('buttons_navigation.hyperlink_login')}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md space-y-8">
          {/* Header */}
          <div>
            {/* Logo */}
            <div className="flex justify-center">
              <img
                src={logo}
                className="w-[80px] h-auto"
                alt="GDF 512x512 sized logo"
              />
            </div>
            {/* Notification messages - if any */}
            <h2 className="mt-2 text-center text-3xl tracking-tight text-gray-400">
              {reqError ? (
                <span className="text-red-400 text-lg">{reqError}</span>
              ) : (
                ""
              )}
            </h2>
          </div>

          {shouldSpin ? (
            <div className="border rounded-lg p-8 sm:p-2">
              <Spinner />
            </div>
          ) : revealResp !== "" ? (
            <div className="border rounded-lg p-8 sm:p-2">
              <span className="block">{revealResp}</span>
            </div>
          ) : (
            <form className="mt-8 space-y-6" autoComplete="off" method="POST">
              <input type="hidden" name="remember" defaultValue="true" />
              <div>
                <div className="-space-y-px rounded-md shadow-sm text-lg">
                  <div>
                    <label htmlFor="email-address" className="sr-only">
                      {/* //  this needs translation  Email address */}
                      {t('forgot_password_page.email_address')}
                    </label>
                    <input
                      id="email-address"
                      name="email"
                      type="email"
                      onChange={handleEmail}
                      autoComplete="off"
                      required={true}
                      className="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-[#24478f]/30 focus:outline-none focus:ring-[#24478f]/30"
                      placeholder={t('login_register_page.enter_email_address')}
                    />
                  </div>
                </div>
              </div>
              <div className="space-y-2">
                {/* Register Button */}
                <div>
                  <button
                    type="submit"
                    onClick={handleForgotPass}
                    className="group relative flex w-full justify-center rounded-md border border-transparent bg-slate-500 py-2 px-4 text-sm font-medium text-white hover:bg-slate-600 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2 uppercase"
                  >
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                      <LockSolidOpenedIcon
                        className="h-5 w-5 text-gray-500 group-hover:text-indigo-400"
                        aria-hidden="true"
                      />
                    </span>
                    {/*Reset Password*/}
                    {t('buttons_navigation.button_nav_reset_password')}
                  </button>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </>
  );
};

export { ForgotPass };
