import { useState, useEffect, useLayoutEffect, useRef } from 'react';

import { Footer, SelectInputV2, TableDAtaView, Spinner} from '../../components';

import { useHandleSubmit, useEnaDisableStyle } from '../../hooks';
import { useEnv } from '../../contexts';
import { getResource } from '../../services/farmApi';

import { useTranslation } from "react-i18next";

function EventsReport() {

    // for language translation
    const { t } = useTranslation();

    const [report, setReport] = useState({filter: t('search_page.select_report')});
    const [isSearching, setIsSearching] = useState(false);
    const [cattleEventsData, setCattleEventsData] = useState([]);
    const [cattleEventsViewData, setCattleEventsViewData] = useState([]);
    const [tableSettings, setTableSettings] = useState({idUsage: 1, useSerial: true}); // Influence how table is displayed
    const initNotice = useRef({
        msg: "",
        showNotice: false,
        succ: false,
        status: null,    
    });
    const [createNotice, setCreateNotice] = useState(initNotice.current);
    const { handleGetResource } = useHandleSubmit(); // Shared submit handler
    const { envState, setEnvState} = useEnv(); // Get Environment context

    // Filter Select options
    const reportOptions = useRef([
        ["default", t('search_page.select_report')],
        ["vaccination", t('table_formatter.table_vaccination')],
        ["serviced", t('table_formatter.table_serviced')],
        ["calved", t('table_formatter.table_calved')],
        ["heat", t('table_formatter.table_done_heat')],
        ["treatment", t('table_formatter.table_treatment')],
        ["prostaglandin", t('events_page.hormone')],                    
        // ["disease", t('table_formatter.table_disease')],
        ["dewormed", t('table_formatter.table_de_wormed')],
        ["dried", t('table_formatter.table_done_dried')],
        ["castration", t('table_formatter.table_castration')],
        ["culled", t('table_formatter.table_culled')],
        ["sold", t('table_formatter.table_sold')],
        ["dehorned", t('table_formatter.table_de_horned')],
        ["died", t('table_formatter.table_death')],
        ["others", t('page_elements.others_many_items')],
    ]);

    // console.log("Events Report: whats in the data", cattleEventsData);
    const textInputStyle = useEnaDisableStyle();

    // Notifiy the App of this location, provide Header Nav links
    useEffect(() => {
        setEnvState(state => ({...state, whereAbout: t('navigation_element.nav_item_title_events_report'), backPath: "/fe/reports", homePath: "/fe"}));

        return () => {
            setEnvState(state => ({...state, whereAbout: "", backPath: "", homePath: ""}));
        }
        // eslint-disable-next-line
    }, []);

    // Handle Select input change
    const handleReportChange = (inputKey, inputValue) => {
        setReport({[inputKey]: inputValue});
        // console.log('input key: ' + inputKey);
        // console.log('input value: ' + inputValue)
    }

    // Fetch report
    useLayoutEffect(() => {
        // Handle report report
        const handleFilterChange = (filter, idUsage = 1, useSerial = true) => {
            // console.log("got filer:", filter);
            // Query the report
            getPregnancyReport("/?filter=" + filter);
            // Make any required changes
            setTableSettings({idUsage, useSerial});
        }

        // Handle clear report filter
        const handleReset = () => {
            cattleEventsViewData.length > 0 && setCattleEventsViewData([]);
            cattleEventsData.length > 0 && setCattleEventsData([]);
            // setCreateNotice(initNotice.current);
        }

        const getPregnancyReport = (reportQuery) => {
            handleGetResource(
                null,
                getResource,
                envState.online,
                process.env.REACT_APP_EVENTS + reportQuery,
                setCreateNotice,
                null,
                setCattleEventsData,
                setIsSearching,
                false // explicitly expecting a single model?
            );
        }

        switch (report.filter) {
            case reportOptions.current[1][0]:
                handleFilterChange(reportOptions.current[1][0]);
                break;
            case reportOptions.current[2][0]:
                handleFilterChange(reportOptions.current[2][0]);
                break;
            case reportOptions.current[3][0]:
                handleFilterChange(reportOptions.current[3][0]);
                break;
            case reportOptions.current[4][0]:
                handleFilterChange(reportOptions.current[4][0]);
                break;
            case reportOptions.current[5][0]:
                handleFilterChange(reportOptions.current[5][0]);
                break;
            case reportOptions.current[6][0]:
                handleFilterChange(reportOptions.current[6][0]);
                break;
            case reportOptions.current[7][0]:
                handleFilterChange(reportOptions.current[7][0]);
                break;
            case reportOptions.current[8][0]:
                handleFilterChange(reportOptions.current[8][0]);
                break;
            case reportOptions.current[9][0]:
                handleFilterChange(reportOptions.current[9][0]);
                break;
            case reportOptions.current[10][0]:
                handleFilterChange(reportOptions.current[10][0]);
                break;
            case reportOptions.current[11][0]:
                handleFilterChange(reportOptions.current[11][0]);
                break;
            case reportOptions.current[12][0]:
                handleFilterChange(reportOptions.current[12][0]);
                break;
            case reportOptions.current[13][0]:
                handleFilterChange(reportOptions.current[13][0]);
                break;
            case reportOptions.current[14][0]:
                handleFilterChange(reportOptions.current[14][0]);
                break;  
            // case reportOptions.current[14][0]:
            //     handleFilterChange(reportOptions.current[14][0]);
            //     break;                                          
            default:
                handleReset();
                break;
        }
        // eslint-disable-next-line
    }, [report]);

    // Display report
    useLayoutEffect(() => {
        if (cattleEventsData.length > 0) {
            setCattleEventsViewData(cattleEventsData);
        } else {
            setCattleEventsViewData([]);
        }
    }, [cattleEventsData]);

    // handle reset report selection
    useLayoutEffect(() => {
        if (createNotice.status !== 200 && createNotice.status !== 404) {
            setReport({filter: reportOptions.current[0][1]}); 
        }
    }, [createNotice.ver, createNotice.status])


    // ----------------------------------
    //       Below is the original source code, commented out one <div to fix horinzontal scroll which wasn't working
    //       I added overflow-x-auto to the container to enable horizontal scrolling.
    //       I adjusted the overflow property as needed based on layout requirements.
    //       Ensure that the content inside the container does not have a fixed width that exceeds the width of the container.
    // ----------------------------------
    
    return (
        <div className="md:grid md:grid-cols-2 md:gap-6 mt-[100px] mb-[100px] overflow-x-auto">
        <div className="col-span-2 flex flex-col justify-center items-center">
            <form autoComplete="off" className="w-full">
                {/*<div className="overflow-hidden w-full">*/}
                        <div className="py-5">
                            <div className="grid grid-cols-6 lg:grid-cols-5">
                                <div className="hidden lg:block lg:col-span-1">{/*spacer*/}</div>
                                {/* Report - select */}
                                <div className="col-span-6 lg:col-span-3 flex justify-center p-2">
                                    <div className="w-[400px]">
                                        {/* <InputLabel
                                            inputId="report"
                                            inputStyle="block text-sm font-medium text-gray-700"
                                            labelText="Select Cattle Report"
                                        /> */}
                                        <SelectInputV2
                                            focus={false}
                                            inputName="filter"
                                            inputId="report"
                                            inputStyle={textInputStyle.current.enabledInput}
                                            inputValue={report.filter}
                                            handleChange={handleReportChange}
                                            choices={reportOptions.current}      
                                        />
                                    </div>
                                </div>
                                <div className="hidden lg:block lg:col-span-1">{/*spacer*/}</div>
                                { isSearching && 
                                    <div className="col-span-6 flex justify-center p-2">
                                        <Spinner /> 
                                    </div>
                                }   
                                {/* {console.log('filter: ' + report.filter )} */}
                                {
                                    createNotice.succ ?
                                            <div className="col-span-6">
                                                <div className="w-full flex justify-center py-2">       
                                        {/* <div className="col-span-6">
                                            <div className="w-full flex justify-center p-2" style={{ marginLeft: report.filter === 'vaccination' ? '4rem' : '0rem' }}>  */}
                                                <TableDAtaView
                                                    tableData={cattleEventsViewData}
                                                    idUsage={tableSettings.idUsage}
                                                    useSerial={tableSettings.useSerial}
                                                    mapHash={{
                                                        event_date: t('table_formatter.table_event_date'),
                                                        event_type: t('table_formatter.table_event'),
                                                        vaccination_date: t('events_page.vaccination'),
                                                        service_date: t('events_page.serviced'),
                                                        calving_date: t('events_page.calved'),
                                                        heat_date: t('events_page.heat'),
                                                        treatment_date: t('events_page.treatment'),
                                                        prostaglandin_date: t('events_page.hormone'),
                                                        // disease_date: t('events_page.disease'),
                                                        deworming_date: t('events_page.de_worm'),
                                                        dried_date: t('events_page.dried'),
                                                        castration_date: t('events_page.castration'),
                                                        culling_date: t('events_page.culled'),
                                                        sale_date: t('events_page.sold'),
                                                        dehorning_date: t('events_page.de_horned'),
                                                        treatment: t('events_page.treatment'),
                                                        died_date: t('events_page.died'),
                                                        notes: t('page_elements.notes'),
                                                        "#": "#",
                                                        cattle_name: t("cattle_page.name"), 
                                                        medicine: t('table_formatter.table_medicine'),
                                                        repeat_date: t('table_formatter.table_repeat'),
                                                    }}
                                                />
                                            </div>
                                        </div> :
                                    createNotice.status === 404 ?
                                        <div className="col-span-6">
                                            <div className="w-full flex justify-center py-2">
                                                <span> {t('user_handle_notice.no_data_matching_criteria')}</span>
                                            </div>
                                        </div> :
                                    ""
                                }
                                <div className="hidden lg:block lg:col-span-1">{/*spacer*/}</div>
                            </div>
                        </div>
                        <Footer respNotice={createNotice} setRespNotice={setCreateNotice} backLink="/fe/reports" footerType="nav" />
                    {/*</div>*/}
                </form>
            </div>  
        </div>
    );
}

export { EventsReport };