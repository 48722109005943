import { createResource } from "./farmApi";
import Swal from 'sweetalert2';

// TODO: work on managing storage space & using async indexedDb

/**
 * Manages queueing of POST requests
 * @param {String} reqUrl - api URL
 * @param {String} reqData - the payload
 * @return Object with success status - true, request queued, false, failed to queue
 */
  

export const queuePost =  (reqUrl, reqData, t) => {

    // Create an entry key using the entire date-time string
    const newKey = new Date().toISOString();

    // Ensure key does not exist in local storage
    const currentQueue = localStorage.getItem(newKey);

    if (currentQueue) {
        // Key exists, prompt for another attempt
        //console.log(newKey, "key exists! Try again");
        return ({
            success: false,
            message: t("error_message.failed_to_queue"), // message: "Failed to queue, please try again" 
        })
    } else {
        // Create a new entry
        localStorage.setItem(newKey, JSON.stringify({
            reqUrl,
            reqData,
            respCode: "",
            respMessage: "",
            respErrors: ""
        }));
        return ({
            success: true,
            message: t("error_message.request_queued"), // message: "Request, queued"
        })
    }
}

/**
 * Checks whether or not the key is a valid queue key
 * @param {string} key A key to verify
 * @returns true for valid key, false for invalid one
 */
const isValidKey = (key) => {
    return /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/.test(key);
}

/**
 * Counts the entries in the Queue
 * @returns The lenght of the Queue
 */
export const getQueueLength= () => {
    let count = 0;
    for (let x = 0; x < localStorage.length; x++) {
        if (isValidKey(localStorage.key(x))) {
            count ++;
        }
    }
    return count;
}

/**
 * Attempts to submit all queued Create Post requests
 * @return Count of unsuccesfuly processed POST requests
 */
export const clearQueue = async () => {
    let failedCount = 0;
    const keys = [];

    // Take snapshot of length as localStorage may get
    //  updated from other clients within the domain(during this run)
    const queueLength = localStorage.length;

    // Loop to get all valid keys
    for (let x = 0; x < queueLength; x++ ) {
        // Check key's validity
        const entryKey = localStorage.key(x);
        
        if (isValidKey(entryKey)) {
            keys.push(entryKey);
        } 
    }

    for (const key of keys ) {
        //console.log("here is key" + key);
        const entry = JSON.parse(localStorage.getItem(key));
        //console.log("obtained entry:", entry);
        try {
            const resp = await createResource(entry.reqUrl, entry.reqData);
            if (resp.success) {
                // Successfully posted
                localStorage.removeItem(key);
                //console.log("Successfully posted" + key);

            } else {
                // with failedCount user will be handled using function editQueue
                entry.respCode = resp.status;
                entry.respMessage = resp.message;
                entry.respErrors = resp.errors;
                // Update queue
                localStorage.setItem(key, JSON.stringify(entry));
                failedCount++
                // console.log("Not Successfully posted entry with" 
                //                     + " key: " + key 
                //                     + " status: " +  entry.respCode 
                //                     + " message: " +  entry.respMessage 
                //                     + " errors id: " + entry.respErrors.id[0]
                //                     + " reqUrl: " + entry.reqUrl
                //                     + " reqData: ", entry.reqData
                //                     + " failedCount: " + failedCount
                //             );
            }
        } catch(error) {
            console.log("Error catched in clearing a queue entry",error);
        }
    };

    return failedCount;
}

export const editQueue = async (failedCount, navigate, t) => {

    const keys = [];

    // paramters in the form of API url to establish which form to use
    const REACT_APP_CATTLE="/api/cattle"
    const REACT_APP_EVENTS="/api/cattle-events"
    const REACT_APP_MILKINGS="/api/milkings"
    const REACT_APP_DIPS="/api/dips"
    const REACT_APP_VISITS="/api/visits"
    const REACT_APP_WASHES="/api/washes"
    const REACT_APP_WEIGHINGS="/api/weighings"

    // get a refreshed queue
    const newQueueLength = localStorage.length;

    Swal.fire({
        title: t('user_handle_notice.queue_failed_title_part_1') + failedCount + t('user_handle_notice.queue_failed_title_part_2'),
        text: t('user_handle_notice.queue_failed_text'),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: t('user_handle_notice.queue_confirmation_button_text'),
        cancelButtonText: t('user_handle_notice.queue_cancel_button_text'),
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
    }).then((result) => {
        if (result.isConfirmed) {
            // Action to take if user clicks Review (OK)
            // console.log("User clicked Review");
            // : handle a Review
            //       1. get this first on the queue
            //       2. check its submit URL (entry.reqUrl) to establish which form to call
            //       3. call the form and pre-populate with values that you have
            //       4. remove this record from localStorage
            //       4. exit this logic 

            // get keys for the queued records
            for (let x = 0; x < newQueueLength; x++ ) {
                // Check key's validity
                const entryKey = localStorage.key(x);
                
                if (isValidKey(entryKey)) {
                    keys.push(entryKey);
                } 
            }
            // for each key, get the entry
            for (const key of keys ) {

                const entry = JSON.parse(localStorage.getItem(key));

                // get the URL that will be used to establish which form to call
                const formType = entry.reqUrl;
                const queueRecord = entry.reqData;

                console.log('form type URL: ' + formType);
                console.log('form queue data: ' + queueRecord);

                if (queueRecord) {
                    // remove this item from localStorage
                    localStorage.removeItem(key);

                    failedCount -= 1;
                    
                    // Save cattleRecord to local storage with a special key
                    localStorage.setItem('queuedFarmRecord', JSON.stringify(queueRecord));

                } else {
                    console.error('queueRecord is null or undefined');
                }

                switch (formType){
                    case REACT_APP_CATTLE: 

                        // Navigate to cattle.js
                        navigate('./Cattle');
                        
                        break;
                    case REACT_APP_EVENTS: 

                        // Navigate to event.js
                        navigate('./Event');

                        break;
                    case REACT_APP_MILKINGS: 

                        // Navigate to milking.js
                        navigate('./Milking');

                        break;
                    case REACT_APP_DIPS: 

                        // Navigate to dip.js
                        navigate('./Dip');

                        break;
                    case REACT_APP_VISITS:

                        // Navigate to visit.js
                        navigate('./Visit');

                        break;
                    case REACT_APP_WASHES: 

                        // Navigate to wash.js
                        navigate('./Wash');

                        break;
                    case REACT_APP_WEIGHINGS: 

                        // Navigate to weighing.js
                        navigate('./Weighing');

                        break;
                    default:
                        // Handle other cases if needed
                        break;
                }
            }
        } else {
            // Action to take if user clicks Remove (Cancel)
            // Loop to get all valid keys
            for (let x = 0; x < newQueueLength; x++ ) {
                // Check key's validity
                const entryKey = localStorage.key(x);
                
                if (isValidKey(entryKey)) {
                    keys.push(entryKey);
                } 
            }
            for (const key of keys ) {
                localStorage.removeItem(key);

                failedCount = 0;
            }
            Swal.fire({
                title:t('user_handle_notice.queue_success_title_part_1'),
                text: t('user_handle_notice.queue_success_text'),
                icon: "success"
            });
            // requires a refresh
            window.location.reload();
        }
    });


    return failedCount;
}