// TODO: this file is deprecated as I moved the functionality to modal on hamburger menu in header.js
import { useState, useEffect } from "react";
import { useEnv } from "../contexts";

// import logo from "../icons/gdf192.png";
import languageGlobe from "../icons/language360.png";

import { useTranslation } from 'react-i18next';
import Cookies from "js-cookie";

// Landing Auth component - Login Dialog
const LanguageForm = () => {
  const { setEnvState } = useEnv(); // Get Environment context Setter
  const [authError] = useState("");

  // Notifiy the App of this location and Header Nav requirements
  useEffect(() => {
      // console.log('we are on rreports menu');
      setEnvState(state => ({...state, whereAbout: t('navigation_element.nav_item_title_language'), backPath: "", homePath: "/fe"}));
      return () => {
          setEnvState(state => ({...state, whereAbout: "", backPath: "", homePath: ""}));
      }
     // eslint-disable-next-line
   }, []);

  // --------------------- start language matter --------------------------
  // for language
  const { t, i18n } = useTranslation();

  // Languages for the dropdown 
  // Emoji flag symbols https://apps.timwhitlock.info/emoji/tables/iso3166
  const languages = [
      { name: " \uD83C\uDDEC\uD83C\uDDE7 English", code: "en" },
      { name: "\uD83C\uDDF9\uD83C\uDDFF Kiswahili", code: "sw" },
  ];

  // for language 
  // read a cookie from the storage and accepts the name of the cookie
  const currentLocale = Cookies.get("gdf_i18next") || "en";

  // define a language variable and pass in the current locale via the useState Hook.
  const [language, setLanguage] = useState(currentLocale);

  // handleChangeLocale handler to trigger language update.
  const handleChangeLocale = (e) => {
      const lang = e.target.value;
 
      // Check if the selected language is different from the current language
      if (lang !== language) {

        // Update the cookie
        Cookies.set('gdf_i18next', lang, { 
          expires: 365,      // expires in 365 days, adjust as needed
          sameSite: 'None',  // Set SameSite attribute to 'None'
          secure: true       // Set secure to true if your site is served over HTTPS
        });

        // Update the state
        setLanguage(lang);

        // Update the i18n language
        i18n.changeLanguage(lang);
      }
    };
  // --------------------- end language matter --------------------------

  return (
      <div className="flex min-h-full items-center mt-14 md:mt-4 justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md space-y-8">
          {/* Header */}
          <div>
            {/* Language Globe */}
            <div className="flex justify-center">
              <img
                src={languageGlobe}
                className="w-[80px] h-auto"
                alt="GDF 360x360 sized language globe"
              />
            </div>
            {/* Notification messages - if any */}
            <h2 className="mt-2 text-center text-3xl tracking-tight text-gray-400">
              {authError ? (
                <span className="text-red-400 text-lg">{authError}</span>
              ) : (
                <span className="text-gray-600 text-lg">
                  {/*Login if you already have an account*/}
                  {t('hamburger_messages.change_language_information')}
                </span>
              )}
            </h2>
          </div>
            {/* Language  */}
          <div className="w-full flex justify-center px-4 sm:px-6 lg:px-8">
            <div className="w-full max-w-md">
              <div className="flex justify-center items-center pt-6">
                {/** start using dropdown */}
                <div>
                    {/* Language switch dropdown here */}
                    <label htmlFor="lang">
                        {/* the language has the current locale, we can pass it to the value prop */}
                        <select className="block border-gray-300 shadow-sm focus:border-slate-300 focus:ring-slate-300 sm:text-sm"
                                id="lang"
                                name="lang"
                                onChange={handleChangeLocale} 
                                value={language}
                        >
                            {languages.map(({ name, code }) => (
                            <option key={code} value={code}>
                                {name}
                            </option>
                            ))}
                        </select>
                      </label>
                </div>
                {/** end using dropdown */}
              </div>
            </div>
          </div>
          <div className="flex justify-center items-center pt-6" />
          <div className="flex flex-col justify-center items-center mt-14 md:mt-8 text-sm text-gray-400/90 font-semibold border-t-[3px] border-gray-200 pt-3">
                      <span>{t('home_page.courtesy')}</span>
                      <span>{t('home_page.web')}</span>
                      <span>{t('home_page.email')}</span>
                      <span>{t('home_page.copyright')}</span>
          </div>
        </div>
      </div>
  );
  
};


export {LanguageForm};
