import { useState, useEffect, useLayoutEffect, useRef } from "react";
import { Chart } from "react-google-charts";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  Footer,
  SelectInputV2,
  TableDAtaView,
  EditDelTableDataView,
  Spinner,
} from "../../components";
import { MilkingForm } from "../Milking";
import "./reportStyle.css";

import { useHandleSubmit, useEnaDisableStyle } from "../../hooks";
import { useEnv } from "../../contexts";
import {
  getResource,
  deleteResource,
  updateResource,
} from "../../services/farmApi";

import { useTranslation } from "react-i18next";

function MilkingsReport() {
  // for language translation
  const { t } = useTranslation();

  const showToast = (message, type) => {
    toast[type](message, {
      position: "top-center", 
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const [report, setReport] = useState({
    filter: t("search_page.select_report"),
  }); 
  const [isSearching, setIsSearching] = useState(false);
  const [milkingData, setMilkingData] = useState([]);
  const [milkingRecord, setMilkingRecord] = useState({});
  const [milkingViewData, setMilkingViewData] = useState([]);
  // Influences how table is displayed
  const [tableSettings, setTableSettings] = useState({
    idUsage: 1,
    useSerial: true,
  });
  const initNotice = useRef({
    msg: "",
    showNotice: false,
    succ: false,
    status: null,
    ver: null,
  });

  const [createNotice, setCreateNotice] = useState(initNotice.current);
  // : see if you can safely remove unused fetchNotice
  const [fetchNotice, setFetchNotice] = useState(initNotice.current);
  
  const [chartData, setChartData] = useState([]);
  const [chartOptions, setChartOptions] = useState({});

  const { handleGetResource, handleSubmit } = useHandleSubmit();
  const { envState, setEnvState } = useEnv(); // Get Environment context

  // Filter Select options
  const reportOptions = useRef([
    ["default", t("search_page.select_report")],
    ["today", t("page_elements.today")],
    ["month", t("page_elements.30_days")],
    ["quarter", t("page_elements.90_days")],
    ["trend", t("page_elements.trend")],
    ["twoday", t("page_elements.twoday")],
  ]);
  // console.log("Events Report: whats in the data", milkingData);
  const textInputStyle = useEnaDisableStyle();

  // ID for the currently selected record in Edit and Delete table
  const [selectedId, setSelectedId] = useState(0);

  //
  const [showMilkingForm, setShowMilkingForm] = useState(false);

  // Foot Type; nav -> default, editDelete -> to show edit and delete buttons
  const [footerType, setFooterType] = useState("nav");

  const [fetchCount, setFetchCount] = useState(0);

  // Handle Radio's input record selection on Edit and Delete table
  const handleOnSelectId = (inputKey, inputValue) => {
    // Check if the radio button is clicked
    if (inputKey === 'selectedId') {
    } else {
        // When the row is clicked, swap inputKey and inputValue
        // console.log("row is selected here");
        inputValue = inputKey;
        inputKey = 'selectedId';
    }

    // Always set inputKey to 'selectedId'
    inputKey = 'selectedId';

    // console.log('final input key: ', inputKey);
    // console.log('final input value: ', inputValue);

    setSelectedId(inputValue);
  };

  /**
   * Notifiy the App of this location, provide Header Nav links
   */
  useEffect(() => {
    setEnvState((state) => ({
      ...state,
      whereAbout: t("navigation_element.nav_item_title_milking_report"),
      backPath: "/fe/reports",
      homePath: "/fe",
    }));

    return () => {
      setEnvState((state) => ({
        ...state,
        whereAbout: "",
        backPath: "",
        homePath: "",
      }));
    };
    // eslint-disable-next-line
  }, [footerType]);

  // Handle Select input change
  const handleReportChange = (inputKey, inputValue) => {
    setReport({ [inputKey]: inputValue });
  };

  // Fetch report
  useLayoutEffect(() => {
    // Handle report filter change and report fetching
    const handleFilterChange = (filter, idUsage = 1, useSerial = true) => {
      // Query the report
      getMilkingReport("/?filter=" + filter);
      // Make any required changes
      setTableSettings({ idUsage, useSerial });
    };

    // Handle clear report filter
    const handleReset = () => {
      milkingViewData.length > 0 && setMilkingViewData([]);
      milkingData.length > 0 && setMilkingData([]);
    };

    const getMilkingReport = (reportQuery) => {
      handleGetResource(
        null,
        getResource,
        true, //envState.online,
        process.env.REACT_APP_MILKINGS + reportQuery,
        setCreateNotice,
        null,
        setMilkingData,
        setIsSearching,
        false // explicitly expecting a single model?
      );
      // console.log('here is milking data from getMilkingReport: ', milkingViewData);
    };
    // console.log("tasked to fetch with filter", report.filter);
    // console.log("tasked to fetch with count", fetchCount);

    switch (report.filter) {
      case reportOptions.current[1][0]:
        handleFilterChange(reportOptions.current[1][0]);
        break;
      case reportOptions.current[2][0]:
        handleFilterChange(reportOptions.current[2][0]);
        break;
      case reportOptions.current[3][0]:
        handleFilterChange(reportOptions.current[3][0]);
        break;
      case reportOptions.current[4][0]:
        handleFilterChange(reportOptions.current[4][0]);
        setTableSettings((x) => ({ ...x, idUsage: 0 }));
        break;
      case reportOptions.current[5][0]:
        handleFilterChange(reportOptions.current[5][0]);
        break;
      default:
        handleReset();
        break;
    }
    // eslint-disable-next-line
  }, [report, fetchCount]);


  
  // Display report
  useLayoutEffect(() => {
    if (milkingData.length > 0) {
      if (report.filter === "trend") {
        // Branching out to show chart
        // Extract categories (x-axis labels), AM, Noon and PM litres
        // console.log('milking data for graph: ', milkingData);

        const transformedArray = milkingData.map(({date, am_total, noon_total, pm_total}) => {
          return [
            date,
            am_total || 0,
            noon_total || 0,
            pm_total || 0
          ];
        });

        const dataNew = [
          ["Date", "AM", "Noon", "PM"],
          ...transformedArray,
        ];

        const optionsNew = {
          title: "Daily AM, Noon, PM Milk",
          vAxis: { title: "Litre" },
          hAxis: { title: "Date" },
          seriesType: "bars",
          series: { 5: { type: "line" } },
        };

        setChartData(dataNew);
        setChartOptions(optionsNew);
      } else {
        setMilkingViewData(milkingData);
      }
    } else {
      setMilkingViewData([]);
    }
    // eslint-disable-next-line
  }, [milkingData]);

  // handle hide/show edit and delete controls
  useLayoutEffect(() => {
    if (createNotice.succ && report.filter === "twoday") {
      setFooterType("editDelete");
    } else {
      setFooterType("nav");
    }
  }, [createNotice.succ, report.filter]);

  // Added a confirmation dialog or modal before proceeding with the delete operation
  const handleDelete = (e) => {
    e.preventDefault();
    if (selectedId) {
      // Show confirmation dialog
      // console.log("selected record id: " + selectedId);
      const isConfirmed = window.confirm(
        t("validation_messages.confirm_deletion")
      );

      if (isConfirmed) {
        const deleteMilkingUrl =
          process.env.REACT_APP_MILKINGS + "/" + selectedId;

        // Delete the selected Record
        handleSubmit(
          e,
          null,
          deleteResource,
          envState.online,
          deleteMilkingUrl,
          () => {},
          (notice) => {
            setCreateNotice({ ...notice, showNotice: false });
          },
          () => {
            // console.log("old count", fetchCount);
            setFetchCount((x) => x + 1);
          },
          (event) => {
            // console.log("delete request queued!");
          }
        );
      } else {
        // console.log("Deletion cancelled.");
      }
    } else {
      // inform user that nothing has been selected
        // info, error, success are possible options
        showToast(t("validation_messages.select_record_first"), "info");  
    }
  };

  // Fetch a record to edit
  const handleEdit = (e) => {
    e.preventDefault();
    if (selectedId) {
      // e.preventDefault();
      // console.log("You wanna edit?", selectedId);
      const fetchMilkingRecordUrl =
        process.env.REACT_APP_MILKINGS + "/" + selectedId;
      // Delete the selected Record
      handleGetResource(
        null,
        getResource,
        true, //envState.online,
        fetchMilkingRecordUrl,
        setFetchNotice,
        null,
        setMilkingRecord,
        setIsSearching,
        false // explicitly expecting a single model?
      );
    } else {
      // inform user that nothing has been selected
      // console.log("nothing selected - see!>>", selectedId);
      // info, error, success are possible options
      showToast(t("validation_messages.select_record_first"), "info");  
    }
  };

  // Handle Cancel on Edit page
  const handleEditCancel = (e) => {
    e.preventDefault();
    setShowMilkingForm(false);
    setFooterType("editDelete");
  };

  // Handle updating edited record
  const handleEditUpdate = (e, updatedData, milkingID) => {
    e.preventDefault();
    if (milkingID) {
      // e.preventDefault();
      // console.log("You wanna update?", milkingID);
      const updateMilkingUrl = process.env.REACT_APP_MILKINGS + "/" + milkingID;
      // console.log("update URL>>", updateMilkingUrl);
      // console.log("update data>>", updatedData);
      // Delete the selected Record
      handleSubmit(
        e,
        updatedData,
        updateResource,
        envState.online,
        updateMilkingUrl,
        () => {},
        (notice) => {
          setCreateNotice({ ...notice, showNotice: false });
        },
        () => {
          // console.log("old count", fetchCount);
          setFetchCount((x) => x + 1);
          setShowMilkingForm(false);
        },
        (event) => {
          // console.log("update request queued!");
        }
      );
    } else {
      // console.log("nothing selected - see!>>", selectedId);
    }
  };

  // Monitor for availability of requesed milking record for editing
  useEffect(() => {
    // console.log("New miling record", milkingRecord);
    if (milkingRecord?.id) {
      setShowMilkingForm(true);
      setFooterType("edit");
    }
  }, [milkingRecord]);


  if (!showMilkingForm) {
    return (
      <div className="md:grid md:grid-cols-2 md:gap-6 mt-[100px] mb-[100px] overflow-x-auto">
        <div className="col-span-2 flex flex-col justify-center items-center">
          <form autoComplete="off" className="w-full">
            <div className="py-5">
              <div className="grid grid-cols-6 lg:grid-cols-5">
                <div className="hidden lg:block lg:col-span-1">
                  {/*spacer*/}
                </div>
                {/* Report - select */}
                <div className="col-span-6 lg:col-span-3 flex justify-center p-2">
                  <div className="w-[400px]">
                    <SelectInputV2
                      focus={false}
                      inputName="filter"
                      inputId="report"
                      inputStyle={textInputStyle.current.enabledInput}
                      inputValue={report.filter}
                      handleChange={handleReportChange}
                      choices={reportOptions.current}
                    />
                  </div>
                </div>
                <div className="hidden lg:block lg:col-span-1">
                  {/*spacer*/}
                </div>
              </div>

              {isSearching && (
                <div className="grid grid-cols-6 lg:grid-cols-5">
                  <div className="col-span-6 lg:col-span-5 flex justify-center p-2">
                    <Spinner />
                  </div>
                </div>
              )}
              {createNotice.succ ? (
                report.filter === "trend" ? (
                  <div className="grid grid-cols-12 mt-6">
                    <div className="col-span-1">{/*spacer*/}</div>
                    <div className="col-span-10 border-[2px] border-gray-200 rounded-lg">
                      <div className="w-full flex justify-center p-2">
                        <div className="overflow-x-auto frame">
                          <div className="w-full">

                            <Chart
                              chartType="ComboChart"
                              width="100%"
                              height="360px"
                              data={chartData}
                              options={chartOptions}
                            />

                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-1">{/*spacer*/}</div>
                  </div>
                ) : (
                  <div className="grid grid-cols-6 lg:grid-cols-5">
                    <div className="hidden lg:block lg:col-span-1">
                      {/*spacer*/}
                    </div>
                    <div className="col-span-6 lg:col-span-3">
                      <div className="w-full flex justify-center p-2">
                        {report.filter !== "twoday" ? (
                          <TableDAtaView
                            tableData={milkingViewData}
                            idUsage={tableSettings.idUsage}
                            useSerial={tableSettings.useSerial}
                            mapHash={{
                              "#": "#",
                              am_volume: t("table_formatter.table_am"),
                              pm_volume: t("table_formatter.table_pm"),
                              noon_volume: t("table_formatter.table_noon"),
                              date: t("table_formatter.table_date"),
                              total: t("milking_page.total"),
                              session_type: t("table_formatter.table_session"),
                              cattle_id: t("milking_page.min"),
                              cattle_name : t("cattle_page.name"), 
                              volume: t("table_formatter.table_volume"),
                              //date: "Date", // newentry  milking date
                              min_vol: t("milking_page.min"),
                              max_vol: t("milking_page.max"),
                              avg_vol: t("milking_page.average"),
                            }}
                          />
                        ) : (
                          <EditDelTableDataView
                            tableData={milkingViewData}
                            idUsage={0}
                            useSerial={tableSettings.useSerial}
                            mapHash={{
                              "#": "#",
                              am_volume: t("table_formatter.table_am"),
                              pm_volume: t("table_formatter.table_pm"),
                              noon_volume: t("table_formatter.table_noon"),
                              date: t("table_formatter.table_date"),
                              total: t("milking_page.total"),
                              session_type: t("table_formatter.table_session"),
                              cattle_id: t("table_formatter.table_tag"),
                              cattle_name : t("cattle_page.name"),
                              volume: t("table_formatter.table_volume"),
                              //date: "Date", // newentry  milking date
                              min_vol: t("milking_page.min"),
                              max_vol: t("milking_page.max"),
                              avg_vol: t("milking_page.average"),
                            }}
                            selectedId={selectedId}
                            onSelectId={handleOnSelectId}
                          />
                        )}
                      </div>
                    </div>
                    <div className="hidden lg:block lg:col-span-1">
                      {/*spacer*/}
                    </div>
                  </div>
                )
              ) : createNotice.status === 404 ? (
                <div className="grid grid-cols-6 lg:grid-cols-5">
                  <div className="hidden lg:block lg:col-span-1">
                    {/*spacer*/}
                  </div>
                  <div className="col-span-6 lg:col-span-3">
                    <div className="w-full flex justify-center py-2">
                      <span>
                        {t("user_handle_notice.no_data_matching_criteria")}
                      </span>
                    </div>
                  </div>
                  <div className="hidden lg:block lg:col-span-1">
                    {/*spacer*/}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <Footer
              respNotice={createNotice}
              setRespNotice={setCreateNotice}
              backLink="/fe/reports"
              footerType={footerType}
              onDelete={handleDelete}
              onEdit={handleEdit}
            />
            {/*</div>*/}
          </form>
        </div>

        <div>
          {/* Toast Container */}
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            closeOnClick
            pauseOnHover
            draggable
            pauseOnFocusLoss
            theme="light"
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className="mt-[80px] py-4 md:px-8">
        <MilkingForm
          milking={milkingRecord}
          formMode={"edit"}
          onCancel={handleEditCancel}
          onEdit={handleEditUpdate}
        />
      </div>
    );
  }
}

export { MilkingsReport };
