import { useEffect } from "react";
import { useEnv } from "../contexts";
import logo from "../icons/gdfproject.png";
import { useTranslation } from 'react-i18next';

// Landing Auth component - Login Dialog
const AppManual = () => {
  const { setEnvState } = useEnv(); // Get Environment context Setter

  // Notifiy the App of this location and Header Nav requirements
  useEffect(() => {
      // console.log('we are on rreports menu');
      setEnvState(state => ({...state, whereAbout: t('navigation_element.nav_item_title_manual'), backPath: "", homePath: "/fe"}));
      return () => {
          setEnvState(state => ({...state, whereAbout: "", backPath: "", homePath: ""}));
      }
     // eslint-disable-next-line
   }, []);

  // --------------------- start language matter --------------------------
  // for language
  const { t } = useTranslation();
  // --------------------- end language matter --------------------------

  return (
      <div className="flex min-h-full items-center mt-14 md:mt-4 justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md space-y-8">
            {/* Header */}
            <div>
                {/* logo */}
                <div className="flex justify-center">
                <img
                    src={logo}
                    className="w-[80px] h-auto"
                    alt="GDF 512x512 sized logo"
                />
                </div>
            </div>

            {/* Start Content */}
            <div className="text-gray-600/90 text-lg col-span-2 flex flex-col justify-center items-center px-6 bg-gray-50">
                <div>
                    <h2 className="text-xl font-bold mb-2">{t('user_manual_content.welcome_title')}</h2>
                    <p>{t('user_manual_content.welcome_info')}</p>
                </div>

                <div>
                    <h3 className="text-xl font-semibold mt-6">{t('user_manual_content.overview_title')}</h3>
                    <p>{t('user_manual_content.overview_info')}</p>
                </div>

                <div>
                    <h3 className="text-xl font-semibold mt-6">{t('user_manual_content.key_features_title')}</h3>
                    <ol className="list-decimal pl-6">
                        <li>{t('user_manual_content.record_keeping')}</li>
                            <p>{t('user_manual_content.record_keeping_info')}</p>
                        <li>{t('user_manual_content.user_roles')}</li>
                            <p>{t('user_manual_content.user_roles_info')}</p>
                        <li>{t('user_manual_content.offline_data_capture')}</li>
                            <p>{t('user_manual_content.offline_data_capture_info')}</p>
                        <li>{t('user_manual_content.language_selection')}</li>
                            <p>{t('user_manual_content.language_selection_info')}</p>
                        <li>{t('user_manual_content.hamburger_menu')}</li>
                            <p>{t('user_manual_content.hamburger_menu_info')}</p>
                        <li>{t('user_manual_content.share_feature')}</li>
                            <p>{t('user_manual_content.share_feature_info')}</p>
                        <li>{t('user_manual_content.feedback')}</li>
                            <p>{t('user_manual_content.feedback_info')}</p>                            
                    </ol>
                </div>

                <div>

                <h3 className="text-xl font-semibold mt-6">{t('user_manual_content.getting_started_title')}</h3>
                    <ol className="list-decimal pl-6">
                        <li>{t('user_manual_content.home_screen')}</li>
                            <p>{t('user_manual_content.home_screen_info')}</p>
                        <li>{t('user_manual_content.tag_number_system')}</li>
                            <p>{t('user_manual_content.tag_number_system_info')}</p>
                        <li>{t('user_manual_content.enjoy_the_experience')}</li>
                            <p>{t('user_manual_content.enjoy_the_experience_info')}</p>
                        <li>{t('user_manual_content.note')}</li>
                            <p>{t('user_manual_content.note_info')}</p>
                        <li>{t('user_manual_content.share_the_experience')}</li>
                            <p>{t('user_manual_content.share_the_experience_info')}</p>
                        <li>{t('user_manual_content.provide_feedback')}</li>
                            <p>{t('user_manual_content.provide_feedback_info')}</p>
                    </ol>
                </div>

                <div>
                    <h3 className="text-xl font-semibold mt-6">{t('user_manual_content.conclusion')}</h3>
                    <p>{t('user_manual_content.conclusion_info')}</p>
                </div>
            </div>
            {/* End Content */}

            <div className="flex flex-col justify-center items-center mt-14 md:mt-8 text-sm text-gray-400/90 font-semibold border-t-[3px] border-gray-200 pt-3">
                    <span>{t('home_page.courtesy')}</span>
                    <span>{t('home_page.web')}</span>
                    <span>{t('home_page.email')}</span>
                    <span>{t('home_page.copyright')}</span>
            </div>
        </div>
      </div>

  );
  
};


export {AppManual};
