import { useState, useEffect, useLayoutEffect, useRef } from 'react';

import { Footer, SelectInputV2, TableDAtaView, Spinner} from '../../components';

import { useHandleSubmit, useEnaDisableStyle } from '../../hooks';
import { useEnv } from '../../contexts';
import { getResource } from '../../services/farmApi';

import { useTranslation } from "react-i18next";

function PregnancyReport() {

    // for language translation
    const { t } = useTranslation();

    const [report, setReport] = useState({filter: t('search_page.select_report')});
    const [isSearching, setIsSearching] = useState(false);
    const [cattleEventsData, SetCattleEventsData] = useState([]);
    const [cattleEventsViewData, SetCattleEventsViewData] = useState([]);
    const [reportData, setReportData] = useState([]);
    const [createNotice, setCreateNotice] = useState({
        msg: "",
        showNotice: false,
        succ: false,
        status: null,
    });
    const [tableSettings, setTableSettings] = useState({idUsage: 1, useSerial: true}); // Influence how table is displayed
    const { handleGetResource } = useHandleSubmit(); // Shared submit handler
    const { envState, setEnvState} = useEnv(); // Get Environment context

    // Filter Select options
    const reportOptions = useRef([
        ["default", t('search_page.select_report')],
        ["dueDate", t('search_page.due_date')],
        ["dryingDate", t('search_page.drying_date')],
        ["servicedDate", t('search_page.serviced_date')],
        ["servicingDate", t('search_page.servicing_date')],
        ["pregnancycheck", t('search_page.pregnancy_cycle')],   
    ]);

    const textInputStyle = useEnaDisableStyle();

    /**
     * Notifiy the App of this location, provide Header Nav links
     * Fetch resource
     */
    useEffect(() => {
        setEnvState(state => ({...state, whereAbout: t('navigation_element.nav_item_title_pregnancy_report'), backPath: "/fe/reports", homePath: "/fe"}));

        return () => {
            setEnvState(state => ({...state, whereAbout: "", backPath: "", homePath: ""}));
        }
        // eslint-disable-next-line
    }, []);

    // Handle Select input change
    const handleReportChange = (inputKey, inputValue) => {
        setReport({[inputKey]: inputValue});
    }

    // Fetch report
    useLayoutEffect(() => {
        // Handle report report
        const handleReportChange = (filter, idUsage = 1, useSerial = true) => {
            // Query the report
            getPregnancyReport("/?filter=" + filter);
            // Make any required changes
            setTableSettings({idUsage, useSerial});
        }

        // Handle clear report filter
        const handleReset = () => {
            SetCattleEventsViewData([]);
            SetCattleEventsData([]);
        }

        const getPregnancyReport = (reportQuery) => {

            handleGetResource(
                null,
                getResource,
                envState.online,
                process.env.REACT_APP_EVENTS + reportQuery,
                setCreateNotice,
                null,
                SetCattleEventsData,
                setIsSearching,
                false // explicitly expecting a single model?
            );
        }
        
        switch (report.filter) {
            case "dueDate":
                handleReportChange('duedate');
                break;
            case "dryingDate":
                handleReportChange('dryingdate')
                break;
            case "servicedDate":
                handleReportChange('serviceddate')
                break;
            case "servicingDate":
                handleReportChange('servicingdate')
                break;
            case "pregnancycheck":
                break;
            default:
                handleReset();
                break;
        }
        // eslint-disable-next-line
    }, [report]);

    const getPregnancyCheckReportsProcessor =  (e) => {
       
            handleGetResource(
              null,
              getResource,
              envState.online,
              process.env.REACT_APP_EVENTS + "/?filter=pregnancycheck",
              setCreateNotice,
              null,
              setReportData,
              null,
              false // explicitly expecting a single model?
          );

        //   console.log('set data report: ', reportData);
      } 

    // Get the data for pregnancy check report
    useLayoutEffect((idUsage = 1, useSerial = true) => {
        getPregnancyCheckReportsProcessor();
        setTableSettings({idUsage, useSerial});

    }, []);

    // Display report
    useLayoutEffect(() => {
        
        if (cattleEventsData.length > 0) {
            SetCattleEventsViewData(cattleEventsData);
        } else {
            SetCattleEventsViewData([]);
        }

    }, [cattleEventsData]);

    // handle reset report selection
    useLayoutEffect(() => {
        if (createNotice.status === 101) {
            setReport({filter: reportOptions.current[0][1]});
            setCreateNotice(x => ({...x, status: null}));
        }

    }, [createNotice.status])

    return (
        <div className="md:grid md:grid-cols-2 md:gap-6 mt-[100px] mb-[100px] overflow-x-auto">
            <div className="col-span-2 flex flex-col justify-center items-center">
                <form autoComplete="off" className="w-full">
                    <div className="py-5 overflow-x-auto"> {/* Add overflow-x-auto here */}
                        <div className="py-5">
                            <div className="grid grid-cols-6 lg:grid-cols-5">

                                {/*spacer*/}
                                <div className="hidden lg:block lg:col-span-1"></div>
                                {/* Report - select */}
                                <div className="col-span-6 lg:col-span-3 flex justify-center p-2">
                                    <div className="w-[400px]">
                                        {/* <InputLabel
                                            inputId="report"
                                            inputStyle="block text-sm font-medium text-gray-700"
                                            labelText="Select Cattle Report" // translate this Chagua aina ya taarifa
                                        /> */}
                                        <SelectInputV2
                                            focus={false}
                                            inputName="filter"
                                            inputId="report"
                                            inputStyle={textInputStyle.current.enabledInput}
                                            inputValue={report.filter}
                                            handleChange={handleReportChange}
                                            choices={reportOptions.current}      
                                        />
                                    </div>
                                </div> 
                                {/*spacer*/}
                                <div className="hidden lg:block lg:col-span-1"></div>


                                { isSearching && 
                                    <div className="col-span-6 flex justify-center p-2">
                                        <Spinner /> 
                                    </div>
                                }

                                {report.filter !== 'pregnancycheck' ? (
                                    // Render this section if report.filter is not 'pregnancycheck'
                                    createNotice.succ ? (
                                        <div className="col-span-6">
                                            <div className="w-full flex justify-center py-2">
                                                <TableDAtaView
                                                    tableData={cattleEventsViewData}
                                                    idUsage={tableSettings.idUsage}
                                                    useSerial={tableSettings.useSerial}
                                                    mapHash={{
                                                        service_date: t('table_formatter.table_serviced'),
                                                        calve_date: t('events_page.calved'),
                                                        prostaglandin_date: t('events_page.hormone'), 
                                                        drying_date: t('table_formatter.table_drying'),
                                                        servicing_date: t('table_formatter.table_servicing'),
                                                        due_days: t('table_formatter.table_due_days'),
                                                        serviced_date: t('table_formatter.table_serviced'),
                                                        calved_date: t('table_formatter.table_calved'), 
                                                        dob: t('table_formatter.table_dob'),
                                                        cattle_name: t('table_formatter.table_name'), 
                                                        "#": "#"
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    ) : null
                                ) : (
                                        // use our component instead of a custome table
                                        // <div className="col-span-6" style={{ marginLeft: '20px' }}>
                                        <div className="col-span-6">
                                            <div className="w-full flex justify-center py-2">
                                                <TableDAtaView
                                                    tableData={reportData}
                                                    idUsage={tableSettings.idUsage}
                                                    useSerial={tableSettings.useSerial}
                                                    mapHash={{  
                                                        "#": "#",         
                                                        id: "Tag",
                                                        Name: t('table_formatter.table_name'),
                                                        Age: t('table_formatter.table_age'),
                                                        Calved: t('table_formatter.table_done_calved'),
                                                        Hormone: t('events_page.hormone'),                       
                                                        Heat: t('table_formatter.table_done_heat'),
                                                        Mounted: t('table_formatter.table_mounted'),
                                                        Dried: t('table_formatter.table_done_dried'),
                                                    }}
                                                />
                                            </div>
                                        </div> 
                                )}
                            </div>
                        </div>
                        <Footer respNotice={createNotice} setRespNotice={setCreateNotice} backLink="/fe/reports" footerType="nav" />
                    </div>
                </form>
            </div>  
        </div>
    );
}

export { PregnancyReport };