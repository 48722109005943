import React, { useEffect } from "react";
import { useEnv } from "../contexts";
import logo from "../icons/gdfproject.png";
import { useTranslation } from 'react-i18next';

import Faq from "react-faq-component";

// Landing Auth component - Login Dialog
const FaqBoard = () => {
    const { setEnvState } = useEnv(); // Get Environment context Setter

    // Notifiy the App of this location and Header Nav requirements
    useEffect(() => {
        // console.log('we are on rreports menu');
        setEnvState(state => ({...state, whereAbout: t('navigation_element.nav_item_title_faq'), backPath: "", homePath: "/fe"}));
        return () => {
            setEnvState(state => ({...state, whereAbout: "", backPath: "", homePath: ""}));
        }
        // eslint-disable-next-line
    }, []);

    // --------------------- start language matter --------------------------
    // for language
    const { t } = useTranslation();
    // --------------------- end language matter --------------------------

    const data = {
        title: t('faq_form.faq_title'),
        rows: [
            {
                title: t('faq_form.row_title1'),
                content: t('faq_form.row_content1'),
            },
            {
                title: t('faq_form.row_title2'),
                content: t('faq_form.row_content2'),
            },
            {
                title: t('faq_form.row_title3'),
                content: t('faq_form.row_content3'),
            },
            {
                title: t('faq_form.row_title4'),
                content: t('faq_form.row_content4'),
            },
            {
                title: t('faq_form.row_title5'),
                content: t('faq_form.row_content5'),
            },
            {
                title: t('faq_form.row_title6'),
                content: t('faq_form.row_content6'),
            },
            {
                title: t('faq_form.row_title7'),
                content: t('faq_form.row_content7'),
            },
            {
                title: t('faq_form.row_title8'),
                content: t('faq_form.row_content8'),
            }, 
            {
                title: t('faq_form.row_title9'),
                content: t('faq_form.row_content9'),
            },
            {
                title: t('faq_form.row_title10'),
                content: t('faq_form.row_content10'),
            },                         
        ],
    };

    const styles = {
          // bgColor: 'white',
          titleTextColor: 'gray',
          titleTextSize: '18px',
          rowTitleColor: 'gray',
          rowTitleTextSize: 'medium',
          rowContentColor: 'gray',
          rowContentTextSize: '16px',
          // rowContentPaddingTop: '10px',
          rowContentPaddingBottom: '10px',
          rowContentPaddingLeft: '50px',
          // rowContentPaddingRight: '150px',
          // arrowColor: "red",
          // transitionDuration: "1s",
          // timingFunc: "ease"
    };

    const config = {
        // animate: true,
        // arrowIcon: "V",
        // openOnload: 0,
        // expandIcon: "+",
        // collapseIcon: "-",
    };



    return (
        <div className="flex min-h-full items-center mt-14 md:mt-4 justify-center py-12 px-4 sm:px-6 lg:px-8">
            <div className="w-full max-w-md space-y-8">
                {/* Header */}
                <div>
                    {/* logo */}
                    <div className="flex justify-center">
                        <img
                            src={logo}
                            className="w-[80px] h-auto"
                            alt="GDF 512x512 sized logo"
                        />
                    </div>
                </div>

                {/* Start Content */}
               <div className="relative z-0">
                    <Faq
                        data={data}
                        styles={styles}
                        config={config}
                    />
                </div>
                {/* End Content */}

                <div className="flex flex-col justify-center items-center mt-14 md:mt-8 text-sm text-gray-400/90 font-semibold ">
                        <span>{t('home_page.courtesy')}</span>
                        <span>{t('home_page.web')}</span>
                        <span>{t('home_page.email')}</span>
                        <span>{t('home_page.copyright')}</span>
                </div>
            </div>
        </div>

    );
  
};

export {FaqBoard};
