import { httpClient } from "./httpClient";

// User login
const login = async (email, password) => {
  
  try {
    // Request for Laravel CSRF cookie
    await httpClient.get(process.env.REACT_APP_CSRF_URL);

    // Authenticate user since axios handles crsf token by default
    const resp = await httpClient.post(process.env.REACT_APP_AUTH_LOGIN, {
      email,
      password,
    });
    return resp.data;
  } catch (error) {
    // console.error("got error", error.response?.status);
    return {
      success: false,
      status: error.response?.status,
      message: error.response?.statusText,
    };
  }
};

// User logout
const logout = async () => {
  try {
    // Unauthenticate usser
    const resp = await httpClient.post(process.env.REACT_APP_AUTH_LOGOUT);
    //console.log("logout response in auth", resp.data);
    return resp.data;
  } catch (error) {

    // : comment out this
    // console.error("got error", error.response?.status);
    // console.log("entire log out error object: ", error);

    return {
      success: false,
      status: error.response?.status,
      message: error.response?.statusText,
    };
  }
};

// User registration
const register = async (regUrl, userPayload) => {
  try {
    const resp = await httpClient.post(regUrl, userPayload);
    return resp.data;
  } catch (error) {

    // : log out user if 401
    if (error.response.status === 401) {handleLogout();}

    return {
      success: false,
      status: error.response?.status,
      message: error.response?.statusText,
      errors: error.response?.data?.errors,
    };
  }
};

// User change password
const changePassword = async (regUrl, userPayload) => {
  try {
    const resp = await httpClient.post(regUrl, userPayload);
    return resp.data;
  } catch (error) {
    // console.log(error);

    // : log out user if 401
    if (error.response.status === 401) {handleLogout();}

    return {
      success: false,
      status: error.response?.status,
      message: error.response?.statusText,
      errors: error.response?.data?.errors,
    };
  }
};

// User authentication status check from localstorage
// Note: There is no need to access API if Token isnt available
// - only solution to this is a fresh login into the API
const checkAuth = () => {
  const userData = localStorage.getItem(process.env.REACT_APP_AUTH_STORAGE_KEY);
  // check for token
  const hasToken = /XSRF-TOKEN\s*=\s*.+/.test(document.cookie);
  //console.log('user data in auth services', userData);
  //console.log('cookies', hasToken);
  return userData && userData !== "undefined" && hasToken
    ? JSON.parse(userData)
    : null;
};

// Create a function to handle logging out the user
const handleLogout = () => {
  localStorage.removeItem(process.env.REACT_APP_AUTH_STORAGE_KEY);
    // Force page refresh and redirect to login page
    window.location.href = "/fe";
};

export { login, logout, register, checkAuth, changePassword };
